import React, { useContext, useLayoutEffect } from 'react'

import Footer from '../Components/Footer/Footer';
import AppSeller from './AppSeller';
import AppNormalUser from './AppNormalUser';
import { IsSellercontext } from '../Context/IsSellerContext';
import { postRequest } from '../Resquest';
import { UserContext } from '../Context/AuthContext';

const AppUser = () => {
	const [isSellerAccount, setIsSellerAccount] = useContext(IsSellercontext)
	const [user, setUser] = useContext(UserContext)

	useLayoutEffect(() => {
		validateToken()
	}, []);

	const validateToken = async() => {
		const valid = await checkToken()

		if (valid) {
		}
		else if (valid === false) {
			logOut()
		}
	}

	const checkToken = async() => {
		const res = await postRequest("/auth/token/verify", {"token": user.access})
		return res?.response?.data?.code === "token_not_valid" ? 
			false : res?.statusText === "OK" ? 
			true : ''
	}

	const logOut = async() => {
		setUser('')
		window.location.reload()
	}
	return (
		<>
			{
				isSellerAccount ?
					<AppSeller user={user} />
					:
					<AppNormalUser user={user} />
			}

			<Footer />
		</>
	)
}

export default AppUser
