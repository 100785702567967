import React, { useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './Alert.css';

const Alert = ({ message, show, onClose }) => {
  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        onClose(); // Masquer l'alerte après 10 secondes
      }, 4000); // 10 secondes

      return () => clearTimeout(timer); // Nettoyage si démonté
    }
  }, [show, onClose]);

  return (
    <AnimatePresence>
      {show && (
        <motion.div
          className="notification-popup"
          initial={{ opacity: 0, scale: 0.8 }} // État initial
          animate={{ opacity: 1, scale: 1 }}   // État animé
          exit={{ opacity: 0, scale: 0.8 }}    // État de sortie
          transition={{ duration: 0.3 }}       // Durée de la transition
        >
          <p>{message}</p>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Alert;
