import React, { createContext, useState, useContext } from 'react';

const WithdrawalContext = createContext();

export const WithdrawalProvider = ({ children }) => {
    const [isRequestSuccessful, setIsRequestSuccessful] = useState(false);

    const updateRequestState = (state) => {
        setIsRequestSuccessful(state);
    };

    return (
        <WithdrawalContext.Provider value={{ isRequestSuccessful, updateRequestState }}>
            {children}
        </WithdrawalContext.Provider>
    );
};

export const useWithdrawalContext = () => useContext(WithdrawalContext);