import React, { useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next'
import { deleteRequest, getRequest, patchRequest, postRequest } from '../../../Resquest'
import './Discount.css';
import { async } from 'q';
import { useStats } from 'react-instantsearch';

import { AnimatePresence, motion } from 'framer-motion'
import { VscChromeClose } from 'react-icons/vsc'
import { BsFillCheckCircleFill } from 'react-icons/bs'
import { useNavigate } from 'react-router'

const variants = {
  hidden: {
      y: "10vh",
      opacity: 0,
      transition: {
          duration: 0.1,
          type: "spring",
          damping: 25,
          stiffness: 500,
      }
  },
  visible: {
      y: "0vh",
      opacity: 1,
      transition: {
          duration: 0.1,
          type: "spring",
          damping: 25,
          stiffness: 500,
      }
  },
  exit: {
      y: "10vh",
      opacity: 0,

  }
}

const DiscountedItem = ({ id, name, couponCode, numUses, discount, startDate, endDate, onEdit, onDelete }) => {
  return (
    <tr>
      <td className='mobile_flex'><span className='mobile_label'>Name: </span>{' ' + name}</td>
      <td className='mobile_flex'><span className='mobile_label'>Code: </span>{' ' + couponCode}</td>
      <td className='mobile_flex'><span className='mobile_label'>Uses: </span>{' ' + numUses}</td>
      <td className='mobile_flex'><span className='mobile_label'>Discount: </span>{' ' + discount}</td>
      <td className='mobile_flex'><span className='mobile_label'>Start date: </span>{' ' + startDate}</td>
      <td className='mobile_flex'><span className='mobile_label'>End date: </span>{' ' + endDate}</td>
      <td className='mobile_flex'>
         <button onClick={() => onEdit(id)}>Edit</button>
        <button className='mobile_label' onClick={() => onDelete(id)}>Delete</button>
      </td>
      <td className='non_mobile'><button onClick={() => onDelete(id)}>Delete</button></td>
    </tr>
  );
};

const DiscountedList = ({ items, onDelete, onAdd, books }) => {
  const [showForm, setShowForm] = useState(false);
  const [book, setBook] = useState('');
  const [couponCode, setCouponCode] = useState('');
  const [numUses, setNumUses] = useState(1);
  const [discount, setDiscount] = useState(0.1);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [id, setId] = useState()
  const [edit, setEdit] = useState(false);
  const [ebookSlug, setEbookSlug] = useState('')
  const today = new Date().toISOString().split('T')[0];

  const [showStatusModal, setShowStatusModal] = useState(false)
  const [modalStatusSucess, setModalStatusSucess] = useState(false)



    const submitRequest = async (data) => {
        try {
            // Determine the correct URL and method
            const url = data.edit
                ? `/seller/ebook/discount/${data.slug}/`  // If editing, use the slug in the URL
                : "/seller/ebook/discount/";  // Otherwise, use the endpoint for creating a new discount

            const requestMethod = data.edit ? patchRequest : postRequest;  // Use patchRequest if editing, else postRequest

            // Make the API request using the appropriate method
            const res = await requestMethod(url, data);

            console.log(res);

            if (res.status === 201 || res.status === 200) {
                // If successful, show success modal and reload after 3 seconds
                setShowStatusModal(true);
                setModalStatusSucess(true);
                setTimeout(() => {
                    window.location.reload();
                    setShowStatusModal(false);
                }, 3000);
            } else {
                // If request failed, show error modal
                setShowStatusModal(true);
                setModalStatusSucess(false);
                setTimeout(() => {
                    setShowStatusModal(false);
                }, 2000);
            }
        } catch (error) {
            console.error("Error during the request:", error);
            setShowStatusModal(true);
            setModalStatusSucess(false);
            setTimeout(() => {
                setShowStatusModal(false);
            }, 2000);
        }
    };



  const handleSubmit = (e) => {
    e.preventDefault();
    const request_data = {
      edit: edit,
      slug: id,
      ebook: book,
      coupon_code: couponCode,
      limit: numUses,
      discount_value: Math.floor(discount),
      valid_from: startDate,
      untill_to: endDate,
    };
    submitRequest(request_data);
    handleCancel()
  };

  const onEdit = (id) => {
    const current = items.filter(item => item.slug === id)[0]
    setId(current.slug)
    setBook(current.ebook)
    setCouponCode(current.coupon_code)
    setNumUses(current.limit)
    setDiscount(current.discount_value)
    setStartDate(current.valid_from)
    setEndDate(current.untill_to)
    setEbookSlug(current.ebook_slug)
    setEdit(true)
    setShowForm(true)
  }


  /**
   * Reset form state
   */
  const handleCancel = () => {
    setBook('');
    setCouponCode('');
    setNumUses(1);
    setDiscount(0.1);
    setStartDate('');
    setEndDate('');
    setShowForm(false);
    setEdit(false)
    setEbookSlug('')
  }
  

  const validate_date = () => {
    
  }

  const alertModal = (message) => {
    alert(message)
  }

	const { t } = useTranslation()
  return (
    <div className='container'>
      <DiscountState isSuccess={modalStatusSucess} showPopup={showStatusModal} text={'This is discount menu'} />
      <div className='table_wrapper'>
      <div className='earning_table'>
        <table>
          <thead>
          <tr>
              <th>{t("dashboard_business.name")}</th>
              <th>{t("dashboard_business.coupon_code")}</th>
              <th>{t("dashboard_business.use_count")}</th>
              <th>{t("dashboard_business.discount")}</th>
              <th>{t("dashboard_business.start_date")}</th>
              <th>{t("dashboard_business.end_date")}</th>
              {/* <th>Edit</th> */}
              <th>{t("dashboard_business.edit")}</th>
              <th>{t("dashboard_business.delete")}</th>
          </tr>
          </thead>
            <tbody>
            {items?.map(item => (
              <DiscountedItem
                key={item?.slug}
                id={item?.slug}
                name={(books?.filter(b => b.slug === item?.ebook_slug)[0]?.name)}
                couponCode={item?.coupon_code}
                numUses={item?.limit}
                discount={item?.discount_value}
                startDate={item?.valid_from}
                endDate={item?.untill_to}
                onEdit={onEdit}
                onDelete={onDelete}
              />
            ))}
          </tbody>
        </table>
      </div>
      </div>
      <button onClick={() => setShowForm(true)}>{ t("dashboard_business.add") }</button>
      {showForm && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={() => setShowForm(false)}>&times;</span>
            <form onSubmit={handleSubmit}>
              <label>
                Book:
                {
                  edit ? (books?.filter(b => b.slug === ebookSlug)[0]?.name)
                  :
                  <select value={book} onChange={(e) => setBook(e.target.value)}>
                    <option value="">{ t("dashboard_business.select_book") }</option>
                    {books.map(book => (
                      <option key={book.slug} value={book.slug}>{book.name}</option>
                    ))}
                  </select>
                }
              </label>
              <label>
              { t("dashboard_business.coupon_code") }:
                <input type="text" value={couponCode} onChange={(e) => setCouponCode(e.target.value)} />
              </label>
              <label>
              { t("dashboard_business.use_count") }:
                <input type="number" value={numUses} onChange={(e) => setNumUses(parseInt(e.target.value))} />
              </label>
              <label>
              { t("dashboard_business.discount") }:
                <input type="number" step={1} min={1} value={discount} onChange={(e) => setDiscount(parseFloat(e.target.value))} />
              </label>
              <label>
              { t("dashboard_business.start_date") }:
                <input type="date" value={startDate} onBlur={validate_date} min={today} onChange={(e) => setStartDate(e.target.value)} />
              </label>
              <label>
              { t("dashboard_business.end_date") }:
                <input type="date" value={endDate} min={startDate || today} onChange={(e) => setEndDate(e.target.value)} />
              </label>
              <div className='discount_btns'>
                <button type="submit">
                  <span>{edit ? t("randoms.submit_discount") : t("randoms.add_item_btn")}</span>
                  {/* <span>  loading</span> */}
                </button>
                <button type="cancel" className="cancel" onClick={handleCancel}>{ t("dashboard_business.cancel") }</button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};


const DiscountState = ({ showPopup, isSuccess, handleRemoveModal, text }) => {

  const navigate = useNavigate()
  const { t } = useTranslation()
  return (
      <AnimatePresence
          initial={false}
          mode='wait'
      >
          {
              showPopup && (
                  <div className='book_modalWrapper'>
                      <motion.div
                          className='book_notify_modal'
                          variants={variants}
                          initial="hidden"
                          animate="visible"
                          exit="exit"
                      >
                        <div className='add_to_cart_modal_inner discount_modal'>
                          {
                            isSuccess ? 
                            <>
                              <span className='discount_checkmark'><VscChromeClose /></span>
                              <p className='modal_notify_text' >Success</p>
                            </>
                            :
                            <>
                              <span className='discount_checkmark failed'><VscChromeClose /></span>
                              <p className='modal_notify_text' >Failed</p>
                            </>
                          }
                        </div>
                      <span onClick={() => handleRemoveModal}><VscChromeClose /></span>
                      </motion.div>
                  </div>
              )
          }
      </AnimatePresence>
  )
}


const Discount = ({ books }) => {
  const [discountedItems, setDiscountedItems] = useState();


  useLayoutEffect(() => {
    getDiscountedBooks()
  }, []);

  // get discounted books and set state
  const getDiscountedBooks = async () => {
    const response = await getRequest('/seller/ebook/discount/')
    if (response.statusText === "OK") {
      setDiscountedItems(response.data.results)
    } else console.warn(response);
  }

  const handleDelete = async(id) => {
    // Implementation of the delete functionality
    const res = await deleteRequest(`/seller/ebook/discount/${id}/`)
    res.status == 204 && 
    setDiscountedItems(discountedItems.filter(item => item.slug !== id));
  };

    const handleAdd = async (newItem) => {
        if (newItem.edit && newItem.slug) {
            const url = `http://hooyia.herokuapp.com/api/v1/seller/ebook/discount/${newItem.slug}/`;
            console.log("URL for patch request:", url);
            const res = await patchRequest(url, newItem);
            console.log(res);
        } else {
            console.error("Slug is missing or edit flag is not set.");
        }
    };



    return (
    <div>
      <DiscountedList books={books} items={discountedItems} onDelete={handleDelete} onAdd={handleAdd}/>
    </div>
  );
};

export default Discount;