import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import './Earnings.css'
import { Link } from 'react-router-dom'
import { TbTriangleFilled, TbTriangleInvertedFilled } from 'react-icons/tb'
import { getRequest } from '../../../Resquest'
import EarningTable from './EarningTable'
import SalesTable from './SalesTable'

const Earning = ({ user }) => {
    const [sales, setSales] = useState([])
    const [earning, setEarning] = useState([])
    const [funds, setFunds] = useState(0)
    const [expense, setExpense] = useState(0)
    const [balance, setBalance] = useState(0)
    const [tableType, setTableType] = useState('earn')

    useLayoutEffect (() => {
      getSales()
      getEarning()
    }, [])

    useEffect (() => {
      calculations()
    }, [earning])
    

    const getSales = async() => {
        const res = await getRequest('/seller/ebook/owner-sold/')
        if (res.statusText === "OK")
            setSales(res.data.results)
    }
    const getEarning = async() => {
        const res = await getRequest('/seller/ebook/seller-earnings-monthly/')
        if (res.statusText === "OK"){
            setEarning(res.data.results)
        }
    }

    const calculations = () => {
        if (earning?.length > 0) {
            let earned = 0
            earning.filter(item => item.is_paid === false).map(item => earned += Number(item.amount_earned))
            setFunds(earned)
            let spent = 0
            earning.filter(item => item.is_paid === true).map(item => spent += Number(item.amount_earned))
            setExpense(spent)
            setBalance(earned - spent)
        }
    }
	const { t } = useTranslation()

  return (
    <div className='earning_page'>
        <div className='container'>
            <div className='earning_title'>
                <div className='earning_history'>
                    <h2>{t("dashboard_earning.pay_overview")}</h2>
                    <button className='btn_earning_table'>/{t("dashboard_earning.history")}</button>
                </div>
                <Link to='/withdrawals'>{t("dashboard_earning.withdrawal_funds")}</Link>
            </div>
            <div className='earning_summary'>
                <div className='earning_grid_item'>
                    <div className="d_flex_title">
                        <h2>{t("dashboard_earning.funds")}</h2>
                        <div className='withdraw_btn_flex'>
                            <div className='earning_activity earn'>
                                <span><TbTriangleFilled/></span>
                                <span>{t("dashboard_earning.earn")}</span>
                            </div>
                            {/* <button type='button' className='withdraw_btn'>Withdraw funds</button> */}
                        </div>
                    </div>
                    <p className='earning_amount'>XAF {funds}</p>
                </div>
                <div className='earning_grid_item'>
                    <div className="d_flex_title">
                        <h2>{t("dashboard_earning.expenses")}</h2>
                        <div className='earning_activity spend'>
                        <span><TbTriangleInvertedFilled/></span>
                            <span>{t("dashboard_earning.earn")}</span>
                        </div>
                    </div>
                    <p className='earning_amount'>XAF {expense}</p>
                </div>
                <div className='earning_grid_item'>
                    <div className="d_flex_title">
                        <h2>{t("dashboard_earning.balance")}</h2>
                        <div className='earning_activity spend'>
                            <span><TbTriangleInvertedFilled/></span>
                            <span>{t("dashboard_earning.earn")}</span>
                        </div>
                    </div>
                    <p className='earning_amount'>XAF {balance}</p>
                </div>
            </div>

            <div className='earning_history filter'>
                <select disabled className='filter_tree_select' id="filter_date">
                    <option value="last_week">{ t("dashboard_earning.time.week") }</option>
                    <option value="last_month">{ t("dashboard_earning.time.month") }</option>
                    <option value="three_months">{ t("dashboard_earning.time.three_months") }</option>
                    <option value="six_months">{ t("dashboard_earning.time.six_months") }</option>
                    <option value="year">{ t("dashboard_earning.time.year") }</option>
                </select>
                <select className='filter_tree_select'value={tableType} onChange={e => setTableType(e.target.value)} id="filter_activity">
                    <option value="earn">{ t("dashboard_earning.earnings") }</option>
                    <option value="sales">{ t("dashboard_earning.sales") }</option>
                    <option value="withdraw">{ t("dashboard_earning.withdrawal") }</option>
                </select>
            </div>
            <div className='table_wrapper'>
                <div className='earning_table'>
                    {
                        tableType === 'earn'
                        ?
                        <EarningTable earning={earning} />
                        :
                        tableType === 'sales'
                        ?
                        <SalesTable sales={sales} /> 
                        :
                        <EarningTable earning={earning} />
                    }
                </div>

            </div>
        </div>
    </div>
  )
}

export default Earning