import React, { useContext, useEffect, useRef, useState } from 'react'
import './Authenticate.css'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { BsGift, BsPhoneVibrate } from 'react-icons/bs'
import { RiGitCommitLine, RiUserStarLine } from 'react-icons/ri'


import FacebookButton from './OAuth/FaceBookButton'
import GoogleButton from './OAuth/GoogleButton'
import { getExternalRequest, postRequest } from '../../../Resquest'
import {ReactComponent as ThreeDots} from '../../../Assets/Loaders/ThreeDots.svg'
import LinkedInButton from './OAuth/LinkedInButton'
import { ReactComponent as SignupSvg } from '../../../Assets/img/signup_svg.svg';
import googleLogo from './OAuth/googleLogo.png'
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa'
import countryList from 'react-select-country-list'
import { MdChangeCircle, MdOutlineEmail, MdOutlineWhatsapp } from 'react-icons/md'
import { VscChromeClose } from 'react-icons/vsc'
import i18next from 'i18next'


const Signup = () => {

    const [fname, setFname] = useState('');
    const [lName, setLname] = useState('');
    const [email, setEmail] = useState('');
        const [isLoading, setIsLoading] = useState(false);
    const [pwd, setPwd] = useState('');
    const [c_pwd, setC_pwd] = useState('');
    const [message, setMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false)
    const [phone, setPhone] = useState('')
    const [verificationMethod, setVerificationMethod] = useState()

    const [countryString, setCountryString] = useState('')
    const [show_country_dropdown, setShow_country_dropdown] = useState(false)
    const [country_search_response, setCountry_search_response] = useState([])
    const [countryList, setCountryList] = useState([])
    const [countryFlag, setCountryFlag] = useState(null)
    const [countryCode, setCountryCode] = useState('')
    const phoneNumberRef = useRef(null)

    const [confirmVerificationMethodModal, setConfirmVerificationMethodModal] = useState(true)


    const navigate = useNavigate()


    const { t } = useTranslation()


    const sendForm = async () => {
        let lang = i18next.language

        const data = {
            'email': email,
            'phone': phone.replace(/\s/g, ''),
            'verification_method': verificationMethod,
            'language': lang,
            'first_name': fname,
            'last_name': lName,
            'password': pwd,
            'password_confirm': c_pwd
        }
        setIsLoading(true)
        const response = await postRequest('/auth/register/', data)
        console.log(response);
        response && setIsLoading(false)
        response?.response?.status=== 306 && alert(t('signupPage.accountAlreadyExist'))
        response?.response?.status=== 403 && alert(t('signupPage.accountAlreadyExistPhone'))
        response?.response?.status=== 400 && alert(t('signupPage.accountAlreadyExist'))

        response.response?.data?.password && alert(response.response?.data?.password.join())

        response.response?.data?.password_confirm && alert(response.response?.data?.password_confirm.join())
        response.code === "ERR_NETWORK" && alert("Network error. Please check your network and retry")
        response?.response?.data?.phone && alert(response?.response?.data?.phone[0])
        
        if(response.statusText === "Created") {
                if(response?.data.verification_method === 'w'){
                        navigate('/verify-nummber', { state: { data: response?.data } })
                }
                if(response?.data.verification_method === 'e'){
                        navigate('/emailverification')
                }
        }
        
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        setConfirmVerificationMethodModal(false)

        setFname(fname.trim())
        setLname(lName.trim())

        sendForm()
        // if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))) {
        //     return alert("invalid email");
        // }
        // else {
        // }
    }

    useEffect(() => {
      fetchCountries()
    }, [])
    

    const alert = (msg) => {
        setMessage(msg)
    }

    const clearForm = () => {
        setFname('')
        setLname('')
        setEmail('')
        setPwd('')
        setC_pwd('')
    }

    const searchCountry = () => {
        let search_list = countryList.filter((country) => country.name.common.toLowerCase().startsWith(countryString))
        setCountry_search_response(search_list)
    }

    const fetchCountries = async() => {
        if( countryList.length < 1){
                const res = await getExternalRequest("https://restcountries.com/v3.1/all?fields=name,flags,code,idd");
                setCountry_search_response(res.data);
                setCountryList(res.data);
        }
    }

    const getCountryList = async(e) => {
        e.target.removeAttribute('readonly')
        setShow_country_dropdown(true)
        fetchCountries()
    }

    const saveCountry = (country) => {
        let code = country?.idd?.root;
        phoneNumberRef.current.focus()
        if(country?.idd?.suffixes.length === 1){
                let suffix = country?.idd?.suffixes[0]
                code = code + suffix + ' '
        }
        setCountryFlag(country?.flags?.svg)
        setCountryCode(code);
        setPhone(code)
        setCountryString(country?.name?.common)

    }

    return (
        <div className='auth_n'>
                <div className='authenticate'>
                        <div className='authenticate__content'>
                                <div className='authenticate__content_container'>
                                        <h3>{ t("auth_title.signup") }</h3>
                                        <div className='mobile-image'>
                                                {/* <img src={Mobile} alt='' /> */}
                                        </div>
                                        <form method="post" onSubmit={handleFormSubmit} autoFill="off">
                                                <div className={`alert ${message ? 'showAlert' : ''}`}>{message}</div>
                                                <div className="inputField">
                                                        <input type="text" autoComplete="new-password" name="uname" onChange={e => setFname(e.target.value)} value={fname} required />
                                                        <label className="label" htmlFor="uname"><span className="labelContent">{ t('signupPage.fname') }</span></label>
                                                </div>

                                                
                                                <div className="inputField">
                                                        <input type="text" name="uname" autoComplete="new-password" onChange={e => setLname(e.target.value)} value={lName} required />
                                                        <label className="label" htmlFor="uname"><span className="labelContent">{ t('signupPage.lname') }</span></label>
                                                </div>

                                                {
                                                        verificationMethod === 'e'
                                                        ?
                                                        <div className="inputField">
                                                                <input type="text" autoComplete="new-password" onChange={e => setEmail(e.target.value)}  value={email} required />
                                                                <label className="label" htmlFor="email"><span className="labelContent">{ t('signupPage.email') }</span></label>
                                                        </div>
                                                        :
                                                        <div className='auth_input_flex'>
                                                                <div className='auth_country_wrapper'>
                                                                        <div className="inputField country_list_auth">
                                                                                <input 
                                                                                        type="text" 
                                                                                        onFocus={ getCountryList } 
                                                                                        onChange={e => setCountryString(e.target.value)} 
                                                                                        onBlur={() => setShow_country_dropdown(false)} 
                                                                                        onKeyUp={searchCountry}
                                                                                        autoComplete="new-password" 
                                                                                        autoCorrect="off"
                                                                                        autoCapitalize="off"
                                                                                        spellCheck="false"
                                                                                        value={countryString} 
                                                                                        readonly
                                                                                        required 
                                                                                />
                                                                                <label className="label" ><span className="labelContent">{ t('dashboard_edit_form.country') }</span></label>
                                                                        </div>
                                                                        {
                                                                                show_country_dropdown && (
                                                                                        <div className='country_list_suggestions'>
                                                                                        {
                                                                                        country_search_response.map(country => (
                                                                                                <div className='country_list_item' onMouseDown={() => saveCountry(country)}>
                                                                                                        <span className="icon"><img src={country.flags.svg} alt={country.name.common} /></span>
                                                                                                        <span className="countryname">{ country.name.common }</span>
                                                                                                </div>
                                                                                        ))
                                                                                        }
                                                                                        </div>
                                                                                )
                                                                        }
                                                                </div>

                                                                <div className="inputField">
                                                                        <input ref={phoneNumberRef} type="text" autoComplete="new-password" onChange={e => setPhone(e.target.value)} name="phone" value={phone} required />
                                                                        <label className="label" htmlFor="email"><span className="labelContent">{ t('signupPage.phone') }</span></label>
                                                                        { countryFlag && <span className='preview_country_icon'><img src={countryFlag} alt={countryCode} /></span> }
                                                                        
                                                                </div>

                                                        </div>
                                                }

                                                

                                                <div className="inputField">
                                                        <input type={showPassword ? "text": "password"} autoComplete="new-password" onChange={e => setPwd(e.target.value)} value={pwd} required />
                                                        <label className="label" htmlFor="password"><span className="labelContent">{ t('signupPage.password') }</span></label>
                                                        <span onClick={() => setShowPassword(!showPassword)} className='show_password_icon'>{ showPassword ? <FaRegEyeSlash /> : <FaRegEye /> }</span>
                                                </div>

                                                <div className="inputField">
                                                        <input type={showPassword ? "text": "password"} autoComplete="new-password" onChange={e => setC_pwd(e.target.value)} value={c_pwd} required />
                                                        <label className="label" htmlFor="password"><span className="labelContent">{ t('signupPage.c_password') }</span></label>
                                                        <span onClick={() => setShowPassword(!showPassword)} className='show_password_icon'>{ showPassword ? <FaRegEyeSlash /> : <FaRegEye /> }</span>
                                                </div>

                                                <div className="buttonWrapper signup">
                                                        <button type="submit" className="submitBtn register">
                                                                <span className='buttonText'>{ t("signupPage.btn") } </span>
                                                                {
                                                                isLoading && <span className='loadingButton'><ThreeDots /></span>
                                                                }
                                                                
                                                        </button>
                                                        <button className="submitBtn changeMethod" title='change authentication method between email and phone' type="button" onClick={() => setConfirmVerificationMethodModal(true)}>
                                                                { t("signupPage.change_method") }
                                                                <span><MdChangeCircle /></span>
                                                        </button>
                                                </div>
                                                <p>{ t('signupPage.already_have_account') } <Link to='/login' className='link'>{ t('signupPage.login') }</Link></p>
                                                <div className="buttonWrapper signupBtn">
                                                        <p className="or">OR</p>
                                                        <div className='other_autheticate'>
                                                                <div className='authBtn googleBtn'>
                                                                        <GoogleButton />
                                                                        <div className='google_placeholder'>
                                                                                <img className="oauthBtnImg" src={googleLogo} alt="google" /> 
                                                                        </div>
                                                                </div>
                                                                <div className='authBtn'>
                                                                        <FacebookButton />
                                                                </div>
                                                                <div className='authBtn'>
                                                                        <LinkedInButton />
                                                                </div>
                                                        </div>
                                                </div>
                                                <div className={`select_confirm_user_method ${ confirmVerificationMethodModal ? 'show_verification_modal_auth': '' }`}>
                                                        <div className='confirm_method_content'>
                                                                {/*<div className='close_confirm_method_modal' onClick={() => setConfirmVerificationMethodModal(false)}><VscChromeClose /></div>*/}
                                                                <p className='confimation_title'>{ t("signupPage.selectMethod") }</p>
                                                                <div className='confirmation_methods_flex'>
                                                                        <div onClick={() => setVerificationMethod('e')} className={`account_confirm_method ${ verificationMethod === 'e' ? 'selected_verification_method': '' }`}>
                                                                                <div className='method_icon'>
                                                                                        <MdOutlineEmail />
                                                                                </div>
                                                                                <p>Email</p>
                                                                        </div>
                                                                        <div onClick={() => setVerificationMethod('w')} className={`account_confirm_method ${ verificationMethod === 'w' ? 'selected_verification_method': '' }`}>
                                                                                <div className='method_icon'>
                                                                                        <MdOutlineWhatsapp />
                                                                                </div>
                                                                                <p>WhatSapp</p>
                                                                        </div>
                                                                </div>
                                                                <button type="button" onClick={() => setConfirmVerificationMethodModal(false)}  className="submitBtn">
                                                                        <span className='buttonText'>{ t("signupPage.select_method") } </span>
                                                                </button>
                                                        </div>
                                                </div>
                                        </form>

                                </div>
                                <div className='signup_convince'>
                                        <div className='convince_item'>
                                                <div className='title'> 
                                                        <span className='iconRegister'> <BsGift /> </span>
                                                        <span> Features </span>
                                                </div>
                                                <p><strong style={{fontWeight: 'bold', color: '#999'}}>{ t('signupPage.feature_one') }</strong> <br />{ t('signupPage.feature_two') }</p>
                                        </div>
                                        <div className='convince_item'>
                                                <div className='title'> 
                                                        <span className='iconRegister'> <RiUserStarLine /> </span>
                                                        <span> Community </span>
                                                </div>
                                                <p>{ t('signupPage.community') }</p>
                                        </div>
                                        <div className='convince_item'>
                                                <div className='title'> 
                                                        <span className='iconRegister'> <RiGitCommitLine /> </span>
                                                        <span> Updates </span>
                                                </div>
                                                <p>{ t('signupPage.updates') }</p>
                                        </div>
                                        <div className='convince_item'>
                                                <div className='svg_login signup_page_specific'>
                                                        <SignupSvg />
                                                </div>
                                        </div>
                                </div>
                        </div>
                </div>
                
        </div>
    )
}

export default Signup