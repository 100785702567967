import axios from "axios";

const user = JSON.parse(localStorage.getItem('user'))

const token = user?.access ? user.access : ""

const configToken = {
    headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
    }
}

const config = {
    headers: {
        "Content-Type": "application/json",
    }
}

const configGlobal = token ? configToken : config

const BASEURL = 'https://hooyia.herokuapp.com/api/v1'

export const AWSbaseURL = 'https://e-hooyia.s3.amazonaws.com/'

export const getRequest = async(endpoint) => {
    try {
        const response = await axios.get(`${BASEURL}${endpoint}`, configGlobal)
        return response
    } catch (err) {
        return err
    }
}
export const getExternalRequest = async(endpoint) => {
    try {
        const response = await axios.get(endpoint)
        return response
    } catch (err) {
        return err
    }
}

export const getRequestWithParams = async(endpoint, params) => {
    try {
        const response = await axios.get(`${BASEURL}${endpoint}`, params, configGlobal)
        return response
    } catch (error) {
        return error
    }
}

export const postRequest = async(endpoint, data) => {
    try {
        const response = await axios.post(`${BASEURL}${endpoint}`, data, configGlobal);
        return response
    } catch (error) {
        // console.error(error);
        return error
    }
}
/**
 * Gets the list of supported countries from the server with their UUID and labels
 * @returns {object} list of countries
 */
export const getCountry = async() => {
    try {
        const response = await axios.get('https://hooyia.herokuapp.com/api/v1/auth/country/', configGlobal);
        return response;
    } catch (error) {
        // console.error(error);
        return error
    }
} 

/**
 * Gets the list of supported banks from server
 * @returns {object} list of countries
 */
export const getAllBanks = async() => {
    try {
        const response = await axios.get('https://hooyia.herokuapp.com/api/v1/auth/bank/', configGlobal);
        console.log(response);
        return response;
    } catch (error) {
        console.error(error);
        return error
    }
} 


/**
 * @param {string} endpoint to which the request is being made to with the id, uuid for a user
 * @param {Object} payload Data you want to update with  
 * @returns {Object}
 */
export const patchRequest = async (endpoint, data) => {
    try {
        // Check if the endpoint is already a full URL
        const url = endpoint.startsWith("http") ? endpoint : `${BASEURL}${endpoint}`;
        console.log("This is the URL used for patch request:", url);
        const response = await axios.patch(url, data, configGlobal);
        return response;
    } catch (error) {
        console.error("Error during patch request:", error);
        return error;
    }
};

export const simplePatchRequest = async(endpoint, data) => {
    try {
        const response = await axios.patch(`${endpoint}`, data, configGlobal);
        return response
    } catch (error) {
        console.error(error);
        return error
    }
}

/**
 * @param {string} endpoint to which the request is being made to with the id, uuid for a user
 * @returns {Boolean}
 */
export const deleteRequest = async(endpoint) => {
    try {
        const response = await axios.delete(`${BASEURL}${endpoint}`, configGlobal);
        return response
    } catch (error) {
        console.error(error);
        return error
    }
}

export const  auth = async (endpoint, data) => {
    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    }
    try {
        const response = await axios.post(`${BASEURL}${endpoint}`, data, config);
        return response
    } catch (error) {
        console.error(error);
        return(error)
    }
}

export const postFetchRequest = async (endpoint, requestData) => {

	const response = await fetch(endpoint, {
		method: 'POST',
		body: requestData,
		dataType: "jsonp",
		headers: {
			'Authorization': token ? `Bearer ${token}` : '',
		}
	})
	const data = response.json()
	return data;
}