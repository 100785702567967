import i18next from 'i18next';
import React, {useEffect, useLayoutEffect, useState} from 'react'
import {Link, Route, Routes} from 'react-router-dom';
import ProductDetails from '../Components/Products/ProductDetails';
import Discount from '../Discount'
import Cart from '../Components/Cart&WishList/Cart';
import {useTranslation} from 'react-i18next';
import Login from '../Components/Auth/Login';
import Logout from '../Components/Auth/Logout';
import CreateSellerAccount from '../Components/SellerAccount/CreateSellerAccount';
import SellerAccountSuccess from '../Components/SellerAccount/SellerAccountSuccess';
import Nav from '../Components/Nav/Nav';
import Home from '../Components/Home/Home';
import NotSellerDashboard from '../Components/SellerAccount/NotSellerDashboard';
import Checkout from '../Components/Checkout/Checkout';
import WishList from '../Components/Cart&WishList/WishList';
import Faq from '../Components/NON-USER/FAQ/Faq';
import UserProfile from '../Components/Profile/UserProfile';
import Settings from '../Components/Profile/Settings';
import MyBooks from '../Components/Products/MyBooks';
import {getRequest} from '../Resquest';
import PaySuccess from '../Components/Checkout/PaySuccess';
import SellerOnboarding from '../Components/SellerAccount/SellerOnboarding';
import StoreProfile from '../Components/Profile/StoreProfile';
import SellerPrivacy from '../Components/SellerAccount/SellerPrivacy';
import Contact from '../Components/NON-USER/Contact/Contact';
import RequestSubmitted from '../Components/NON-USER/Contact/RequestSubmitted';
import FooterU from '../Components/Footer/FooterU';
import RefundPolicy from '../Components/SellerAccount/RefundPolicy';
import PrivacyPolicy from '../Components/SellerAccount/PrivacyPolicy';

const AppNormalUser = ({user}) => {
    const [showModal, setShowModal] = useState(false);
    const [logoutModal, setLogoutModal] = useState(false);
    const [category_list, setCategory_list] = useState([]);
    const [fetching_cat, setFetching_cat] = useState(false);


    i18next.on('languageChanged', () => {
        window.location.reload()
    })
    useEffect(() => {
        fetchCategories()
    }, []);

    const fetchCategories = async () => {
        setFetching_cat(true)
        const response = await getRequest('/public/list-category/')
        response?.data
        && setCategory_list(response?.data?.results)
        setFetching_cat(false)
    }
    // translation function
    const changeLanguage = (lang, e) => {
        i18next.changeLanguage(lang)
        e.target.parentElement.classList.add('hideLang')
    }

    const logoutModalController = () => {
        setLogoutModal(!logoutModal)
    }


    return (
        <>

            {/* <Discount /> */}
            <Nav handleChangeLanguage={changeLanguage} category_list={category_list}
                 handleRemoveAuthModal={logoutModalController}/>
            <Routes>
                <Route path='/ebooks' element={<Home category_list={category_list} isloading={fetching_cat}/>}/>
                <Route path='/' element={<Home category_list={category_list} fetching_cat={fetching_cat}/>}/>
                <Route path='/ebooks/:id' element={<ProductDetails category_list={category_list}/>}/>
                <Route path='/cart' element={<Cart/>}/>
                <Route path='/wishList' element={<WishList/>}/>
                <Route path='/me/:id' element={<StoreProfile/>}/>
                <Route path={`/${user?.user?.first_name}`} element={<UserProfile user={user}/>}/>
                <Route path='/checkout/*' element={<Checkout user={user}/>}/>
                <Route path='/payment-status' element={<PaySuccess/>}/>
                {/* <Route path='/login' element={<Login />} /> */}
                <Route path='/my-books' element={<MyBooks/>}/>
                <Route path='/:id/dashboard' element={<NotSellerDashboard/>}/>
                <Route path={`/${user?.user?.first_name}/settings`} element={<Settings user={user}/>}/>

                {/*  SELLER ACCOUNT PAGES  */}
                <Route path='/seller-onboarding/seller-details/*' element={<CreateSellerAccount/>}/>
                <Route path='/seller-onboarding' element={<SellerOnboarding/>}/>
                <Route path='/terms-and-conditions' element={<SellerPrivacy/>}/>
                <Route path='/seller_account_success' element={<SellerAccountSuccess/>}/>

                {/* CONTACT ADMIN AND FAQ  */}
                <Route path='/faqs/*' element={<Faq/>}/>
                <Route path='/help/contact-support' element={<Contact/>}/>
                <Route path='/help/message-submitted' element={<RequestSubmitted/>}/>
                <Route path='/refund-policy' element={<RefundPolicy/>}/>
                <Route path='/privacy-policy' element={<PrivacyPolicy/>}/>


            </Routes>
            {
                logoutModal && <Logout handleRemoveAuthModal={logoutModalController}/>
            }
            <div className='footter_u_mobile'>
                <FooterU/>
            </div>
        </>
    )
}

export default AppNormalUser
