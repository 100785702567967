import React, { useState, useEffect } from "react"
import './Toast.css'
import { motion } from "framer-motion"
import { VscChromeClose } from "react-icons/vsc"
import { useTranslation } from "react-i18next"
import { VscError } from "react-icons/vsc"
import { CiWarning } from "react-icons/ci"

function WarningToast({message}) {

    
    const [visible, setVisible] = useState(true)

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setVisible(true)
        }, 6000);
        setVisible(false)
        return () => clearTimeout(timeoutId)
    }, [])
    const variants = {
        hidden: {
            y: "10vh",
            opacity: 0,
            transition: {
                duration: 0.1,
                type: "spring",
                damping: 25,
                stiffness: 500,
            }
        },
        visible: {
            y: "0vh",
            opacity: 1,
            transition: {
                duration: 0.1,
                type: "spring",
                damping: 25,
                stiffness: 500,
            }
        },
        exit: {
            y: "10vh",
            opacity: 0,
    
        }
    }

	const { t } = useTranslation()
	return (

                (visible) && 
                        
                <div className='modalWrapper'>
                    <motion.div
                        className='notify_modal'
                        variants={variants}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                    >
                        <span className='checkmark_icon'><CiWarning className="modal_icon warning_icon"/></span>
                        <p className='modal_text'>you're trying to upload a book {message}</p>
                        <span onClick={() => setVisible(false)}><VscChromeClose /></span>
                    </motion.div>
                </div>
	)
}

export default WarningToast