import React, { useEffect, useState, useMemo } from 'react'
import './Withdrawal.css'
import { BsInfoCircleFill } from 'react-icons/bs'
import { useTranslation } from 'react-i18next'
import WithdrawTable from './withdrawal_operations/WithdrawTable'
import { useWithdrawalContext } from '../../../Context/WithdrawalContext'
import { IsSellercontext } from '../../../Context/IsSellerContext'
function Withdrawal({user, books}) {


    const [apiData, setApiData] = useState(null);
    const [withdrawalMade, setWithdrawalMade] = useState('---');
    const [amountWithdrawn, setAmountWithdrawn] = useState('---')
    const [ totalRemaindOnHooyia, setTotalRemaindOnHooyia] = useState(0)
    const [totalEarned, setTotalEarned] = useState('---')
    const [ totalWithdrawable, setTotalWithdrawable] = useState('---');
    const [ totalNotWithdrawable, setNotWithdrawable] = useState('---')
    // const [ sellerBooks, setSellerBooks] = useState(books)
    const [existsPaymentMethod, setExistsPaymentMethod ] = useState(user?.user?.profile?.bank__name)

    const { isRequestSuccessful} = useWithdrawalContext()

    useEffect(() => {
        let timeout;
        if (isRequestSuccessful) {
            console.log('Requête réussie, la page sera rechargée dans 3 minutes.');
            timeout = setTimeout(() => {
                window.location.reload(); // Recharge la page après 3 minutes
            }, 180000); // 3 minutes en millisecondes
        }
        return () => clearTimeout(timeout); // Nettoie le timeout si le composant est démonté
    }, [isRequestSuccessful]);

    const handleDataFetched = (data) => {
        setApiData(data); // Stocker les données dans le parent
    }

    useEffect(() => {
        if (!apiData || !apiData.results) {
          console.warn('apiData ou apiData.results est null ou undefined');
          return; // Arrête l'exécution si apiData est invalide
        }
      
        console.log('valeur de lastElement', apiData.results);
      
        // Récupérer le dernier élément
        const lastElement =
          apiData?.results?.length > 0
            ? apiData.results[apiData.results?.length - 1]
            : null;
      
        // Récupérer la valeur du champ 'total_remaind_on_hooyia'
        const totalRemaindOnHooyia = lastElement
          ? lastElement.total_remaind_on_hooyia
          : null;
        
        
      
        setTotalRemaindOnHooyia(lastElement ? lastElement.total_remaind_on_hooyia : null);
        setTotalEarned(lastElement ? lastElement.total_earned_on_hooyia : null)
        setTotalWithdrawable(lastElement ? lastElement.total_withdrawable_on_hooyia : null)
        setNotWithdrawable(lastElement ? lastElement.total_not_withdrawable_on_hooyia : null)

        // Compter les éléments où is_paid === true
        setWithdrawalMade(apiData.results.filter((item) => item.is_paid === true)?.length);
      }, [apiData]);
      
    const totalWithdrawForCurrentUser = useMemo(() => {
    if (!apiData?.results) return 0;

    return apiData.results
      .filter(item => item.is_paid) // Filtrer les éléments avec `is_paid === true`
      .reduce((total, item) => total + Number(item.total_withdraw_on_hooyia), 0); // Additionner les valeurs
  }, [apiData]);

const { t } = useTranslation()

// console.log(user)
  return (
    <>
        <div className="withdrawal_page">
            <div className="contain">
                <div className="header">
                    <div className="title_page">
                        <h1 className='h1'> {t('withdrawal.withdraw_title')}</h1>
                    </div>
                    <div className="tips">
                        <h3> <BsInfoCircleFill className='icon_item'></BsInfoCircleFill> {t('withdrawal.withdrawal_tips')} </h3>
                    </div>

                </div>
                <div className="body">
                    <div className="body_part left_profile">
                        <h2 className='h2'>{t('withdrawal.seller_info.seller_info_title')}</h2>

                        <div className="info">
                            <div className="info-row">
                                <div className="info-item">
                                    <div className="labelWithdrawal">{t('withdrawal.seller_info.beneficiary_name')}</div>
                                    <div className="value" >{user?.user?.first_name + ' '+user?.user?.last_name}</div>
                                </div>
                                <div className="info-item">
                                    <div className="labelWithdrawal">{t('withdrawal.seller_info.payement_method')}</div>
                                    <div className="value" >{user?.user?.profile?.bank__name ? user?.user?.profile?.bank__name : '---'}</div>
                                </div>
                            </div>

                            <div className="info-row">
                                <div className="info-item">
                                    <div className="labelWithdrawal">{t('withdrawal.seller_info.number_of_books')}</div>
                                    <div className="value" >{books?.length} </div>
                                </div>
                                <div className="info-item">
                                    <div className="labelWithdrawal">{t('withdrawal.seller_info.number_of_sales')}</div>
                                    <div className="value" >{apiData?.results?.length} </div>
                                </div>
                            </div>
                            <div className="info-row">
                                <div className="info-item">
                                    <div className="labelWithdrawal">{t('withdrawal.seller_info.withdrawal_made')}</div>
                                    <div className="value" >{withdrawalMade} </div>
                                </div>
                                <div className="info-item">
                                    <div className="labelWithdrawal">{t('withdrawal.seller_info.amount_withdrawn')}</div>
                                    <div className="value" >{totalWithdrawForCurrentUser} XAF</div>
                                </div>
                            </div>
                            <div className="info-row">
                                <div className="info-item">
                                    <div className="labelWithdrawal">{t('withdrawal.seller_info.total_earned')}</div>
                                    <div className="value">{totalEarned}</div>
                                </div>
                                <div className="info-item">
                                    <div className="labelWithdrawal">{t('withdrawal.seller_info.total_remaining')}</div>
                                    <div className="value">{totalRemaindOnHooyia}</div>
                                </div>
                            </div>
                            <div className="info-row">
                                <div className="info-item">
                                    <div className="labelWithdrawal">{t('withdrawal.seller_info.total_withdrawable')}</div>
                                    <div className="value">{totalWithdrawable}</div>
                                </div>
                                <div className="info-item">
                                    <div className="labelWithdrawal">{t('withdrawal.seller_info.not_withdrawable')}</div>
                                    <div className="value">{totalNotWithdrawable}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=" body_part right_profile">
                        <h2 className='h2'>{t('withdrawal.withdrawal_board.title')} </h2>
                        <div>
                            <div>
                                { <WithdrawTable 
                                    onDataFetched={handleDataFetched}
                                    paymentMethod={existsPaymentMethod}
                                     ></WithdrawTable> } 
                            </div>                
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Withdrawal