import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Cell } from 'recharts';

const DataBar = ({ value, maxQuantity }) => {
    // Determine the bar color based on quantity
    const getBarColor = (quantity) => {
        if (quantity <= 10) return '#ff4d4f'; // Red
        if (quantity <= 20) return '#ffc53d'; // Yellow
        return '#52c41a'; // Green
    };

    const barData = [{ name: 'Quantity', value }];

    return (
        <div style={{ position: 'relative', width: '150px', height: '20px', overflow: 'visible' }}>
            <BarChart
                width={150}
                height={20}
                data={barData}
                layout="vertical"
                margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                barSize={20}
            >
                {/* Scale X-axis by maxQuantity */}
                <XAxis type="number" domain={[0, maxQuantity]} hide />
                <YAxis type="category" dataKey="name" hide />
                <Bar dataKey="value">
                    <Cell fill={getBarColor(value)} />
                </Bar>
            </BarChart>
            {/* Display the numeric value */}
            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    left: '10px',
                    fontWeight: 'bold',
                    color: '#000',
                    fontSize: '12px',
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                }}
            >
                {value}
            </div>
        </div>
    );
};

export default DataBar;
