import React from 'react';
import { useTranslation } from 'react-i18next'; 

const StatisticsFilter = ({ filter, onChange }) => {

    const { t } = useTranslation()
    return (
        <div className="filters">
            <label htmlFor="filter">{t('statistics.filter_book')} :{" "}</label>
            <select onChange={onChange}>
                <option value="all">{t('statistics.all_books')}</option>
                <option value="most">{t('statistics.most_performing_books')}</option>
                <option value="least">{t('statistics.least_performing_books')}</option>
            </select>
        </div>
    )
};

export default StatisticsFilter;