import React, { useEffect, useState } from 'react';

const Pagination = ({ count, currentPage, handlePageCount }) => {
    const [pages, setPages] = useState([]);

    useEffect(() => {
        // Generate an array of page numbers centered around the current page
        const newPages = Array.from({ length: 4 }, (_, i) => currentPage + i);
        setPages(newPages);
    }, [currentPage, count]);

    return (
        <div className='pagination'>
            {pages.map((page, index) => (
                <div 
                    key={index}
                    className={`pagination_count ${page === currentPage ? 'active_btn' : ''}`}
                    onClick={() => handlePageCount(page)}
                > 
                    {page}
                </div>
            ))}
        </div>
    );
};

export default Pagination;
