import React, { useMemo, useState } from 'react'
import Select from 'react-select'


const options = [
        {
                label: "XAF", 
                value: "cm", 

        },
        {
                label: "USD", 
                value: "us", 

        }
]
const CurrencySelect = ({ handleChangeCurrency }) => {
        const [selectedOption, setSelectedOption] = useState(options[0]);
        const [ convertedValue, setConvertedValue ] = useState(0);
        // const changeHandler = value => {
        //         setValue(value)
        // }




        return (
                <>
                        <Select defaultValue={selectedOption} options={options} value={convertedValue} onChange={(value) => handleChangeCurrency(value)} />
                </>
        )
}

export default CurrencySelect
