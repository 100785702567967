import React, { useMemo } from 'react';
import { BarChart, Bar, Cell, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { CustomTooltip } from "./CustomTooltip";
import { CustomizedAxisTick } from "./CustomAxis";
import { useTranslation } from 'react-i18next';
import { data } from '../Data';

const StatisticsChart = ({ revenueData= [], categorySales }) => {

    const formattedData = useMemo(() => {
        if (!Array.isArray(revenueData)) {
            console.error("revenueData is not an array", revenueData);
            return [];
        }

        return revenueData.map((item) => ({
            timePeriod: new Date(item.month).toLocaleDateString("en-US", { month: "long" }),
            sales: item.totalEarned,
            weeklySales: item.weeklyEarnings.map((week) => ({
                week: new Date(week.week).toLocaleDateString("en-US"),
                totalEarned: week.totalEarned,
            })),
        }));
    }, [revenueData]);
    const formattedCategory = Object.keys(categorySales).map((category) => ({
        category: category,
        revenue: categorySales[category],
    }));

    const { t } = useTranslation()
    return (
            <div className="grid_graph">
                <div className="graph_1">
                    <h2 className="seller_heading">{t('statistics.sales_over_time')}</h2>
                    {/* Sales Chart */}
                    <ResponsiveContainer width="100%" height={400}>
                        <LineChart data={formattedData} margin={{top: 20, right: 30, left: 20, bottom: 5}}>
                            <CartesianGrid strokeDasharray="3 3"/>
                            <XAxis dataKey="timePeriod" height={60} tick={<CustomizedAxisTick/>}/>
                            <YAxis
                                dataKey="sales"
                                label={{value: "Sales ($)", angle: -90, position: "insideLeft", dx: -15}}
                            />
                            <YAxis
                                dataKey="monthlySales"
                                label={{
                                    value: "Weekly Sales ($)",
                                    angle: 90,
                                    position: "insideRight",
                                    dx: 15,
                                }}
                                orientation="right"
                            />
                            <Tooltip content={<CustomTooltip data={formattedData}/>}/>
                            <Legend
                                payload={[
                                    { value: 'Total Sales for each Month', type: 'circle', id: 'timePeriod', color: '#8884d8' },
                                ]}
                            />
                            <Line type="monotone" dataKey="sales" stroke="#8884d8" strokeWidth={2}
                                  dot={{r: 5, fill: '#8884d8'}}/>
                        </LineChart>
                    </ResponsiveContainer>
                </div>
                <div className='bar_chart_2'>
                    <h2 className="seller_heading_2">{t('statistics.revenue_per_categories')}:</h2>
                    <ResponsiveContainer width="100%" height={490}>
                        <BarChart data={formattedCategory} margin={{ top: 20, right: 30, left: 0, bottom: 40 }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="category" />
                            <YAxis />
                            <Tooltip />
                            <Bar dataKey="revenue" fill="#01c5c3" />
                            <Legend
                                layout="horizontal"
                                verticalAlign="bottom"
                                align="center"
                                wrapperStyle={{ paddingTop: '100px' }}
                                payload={[
                                    { value: 'Total amount per book Category', type: 'rect', id: 'revenue', color: '#01c5c3' },
                                ]}
                            />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </div>
    );
};

export default StatisticsChart;