import React from 'react';
import DataTable from 'react-data-table-component';
import DataBar from './DataBar';
import { useTranslation } from 'react-i18next';

const Datatable = ({ filteredData }) => {
    const maxQuantity = Math.max(...filteredData.map((row) => row.quantity));

    const { t } = useTranslation();

    return (
        <DataTable
            columns={[
                {
                    name: <div className="row_item_stat row_item_header_stat">{t('statistics.datatable.title')}</div>,
                    selector: (row) => <div className="row_item_stat">{row.title}</div>,
                    sortable: true,
                    center: true
                },
                {
                    name: <div className="row_item_stat row_item_header_stat">{t('statistics.datatable.qty_sold')}</div>,
                    cell: (row) => <DataBar value={row.quantity} maxQuantity={maxQuantity} />,
                    sortable: true,
                    center: true,
                },
                {
                    name: <div className="row_item_stat row_item_header_stat">{t('statistics.datatable.price')} (XAF)</div>,
                    selector: (row) => <div className="row_item_stat">{row.price}</div>,
                    sortable: true,
                    center: true,
                },
                {
                    name: <div className="row_item_stat row_item_header_stat">{t('statistics.datatable.category')}</div>,
                    selector: (row) => <div className="row_item_stat">{row.category}</div>,
                    sortable: true,
                    center: true,
                },
            ]}
            data={filteredData}
            pagination
            striped
            selectableRowsHighlight
            paginationPerPage={5}
            paginationRowsPerPageOptions={[5, 10, 15]}
            highlightOnHover
            fixedHeader
            fixedHeaderScrollHeight="400px"
            responsive
        />
    );
};

export default Datatable;
