import React from 'react'
import AboutUsSection from './AboutUsSection'
import '../../NON-USER/About/newAbout.css'
import { tabTitle } from '../../../index'
import { useTranslation } from 'react-i18next'
import WhatWeDoSection from './WhatWeDoSection'
import SplashSection from './SplashSection'
import LocationSection from './location/LocationSection'

const AboutPage = () => {
  const { t } = useTranslation();

  return (
    <div className='about_page'>
      <div className='landing_explore about_expolore'>
          <div className='about_container'>
            <h1>{ t('about_page.title') } <span>HooYia</span></h1><br />
            <p>{ t('about_page.desc') }<a href="https://hooyia.net/en/" className="s_more">See more</a></p>
          </div>
      </div>
      <WhatWeDoSection />
      {/* <SplashSection  /> */}
      <LocationSection />
      <AboutUsSection />
    </div>
  );
}

export default AboutPage;
