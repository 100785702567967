import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { OnboardActions } from './OnboardActions'

const OnboardBankInfo = ({ handle_form_submit, index, handle_go_back, serverBanks }) => {
    const [isloading, setIsloading] = useState(false)
	const [bank, setBank] = useState('')
	const [accountName, setAccountName] = useState('')
	const [accountNumber, setAccountNumber] = useState('')
	const [swift, setSwift] = useState('')
	const [paypal, setPaypal] = useState('')

    const { t } = useTranslation()

    const data = {
			"name_on_account_number": accountName,
			"bank": bank,
			"account_number": accountNumber,
			"swift": swift,
			"paypal_email_address": paypal,
    }
    const submit_form = () => {
        setIsloading(true)
        handle_form_submit(data)
    }

    const go_back = () => {
        handle_go_back(index);
    }
    

  return (
    <>
        <div className='seller_account_head type'>
            <h2>{ t("start_selling.title_funding") }</h2>
        </div>

        <div className='name_input_flex'>
            <div className='inputContainer flex_full'>
                <select name="bank" value={bank} id="cars" onChange={(e) => setBank(e.target.value)}>
                    <option value="None">--Select Bank--</option>
                    {serverBanks && serverBanks.map(bank => (
                        <option key={bank.pk} value={bank.pk}>{bank.name}</option>
                    ))}
                </select>

            </div>
        </div>
        <div className='name_input_flex'>
            <div className='inputContainer'>
                <input value={accountName} onChange={(e) => setAccountName(e.target.value)} type='text'
                       placeholder={t("start_selling.account_name")}/>
            </div>
            <div className='inputContainer'>
                <input value={accountNumber} onChange={(e) => setAccountNumber(e.target.value)} type='text' placeholder={ t("start_selling.account_number") } />
            </div>
        </div>
        <div className='name_input_flex'>
            <div className='inputContainer'>
                <input value={swift} onChange={(e) => setSwift(e.target.value)} type='text' placeholder={ t("start_selling.swift") } />
            </div>
            <div className='inputContainer'>
                <input value={paypal} onChange={(e) => setPaypal(e.target.value)} type='email' placeholder={ t("start_selling.paypal") } />
            </div>
        </div>
        <OnboardActions save_data={submit_form} handle_go_back={go_back} index={index} finish={true} isloading={isloading} />
    </>
  )
}

export default OnboardBankInfo