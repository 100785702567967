import React from 'react'

const ImageDiscount = ({ image }) => {
  return (
    <div className='imageWrapper discount_info_section'>
      <img className='navLinkImage' src={ image } alt='' />
    </div>
  )
}

export default ImageDiscount
