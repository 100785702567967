import React, { useContext, useEffect, useState } from 'react'
import './Modal.css'
import { postRequest } from '../../../../Resquest'
import {ReactComponent as ThreeDots} from '../../../../Assets/Loaders/ThreeDots.svg';
import  Alert  from './Alert'
import { useWithdrawalContext } from '../../../../Context/WithdrawalContext';
import { useTranslation } from 'react-i18next';
import { CiWarning } from 'react-icons/ci';
const Modal = ({ setModalVisible, id, amount, isWithdrawable, payementMethod}) => {

    const [trackAmountChanges, setTrackAmountChanges] = useState(amount)
    const [message, setMessage] = useState('');
    const [ isLoading, setIsLoading ] = useState(false); 
    const [ formData, setFormData ] = useState({ seller_earning_id: id, password: ''});
    const [ isResponse, setIsResponse] = useState(false);
    const [errors, setErrors] = useState({});

    const { updateRequestState } = useWithdrawalContext()
    const handlePassChange = (value) =>{
        setFormData((prevData) => ({...prevData, password : value }));
    }
    const handleCloseAlert = () => {
      setIsLoading(false);
    };
    const submit = async (e) => {
      e.preventDefault();
    
      // Vérification des erreurs de validation
      if (!validate()) {
        return;
      }
    
      const url = '/transfer-money-to-seller/';
      setIsLoading(true);  // Démarre le loader
      setMessage('');      // Réinitialise le message d'erreur ou de succès
      setIsResponse(false);
    
      try {
        const res = await postRequest(url, formData);
    
        setIsLoading(false); // Arrête le loader après une réponse réussie
        setIsResponse(true);
    
        if (res?.status === 200) {
          setMessage(t('withdrawal.modal.response.success'));
          updateRequestState(true);
        } else if (res.response.status === 406) {
          setMessage(t('withdrawal.modal.response.already_withdawn'));
        } else if (res.response.status === 403) {
          setMessage(t('withdrawal.modal.response.invalid_password'));
        } else if (res.response.status === 404) {
          setMessage(t('withdrawal.modal.response.transaction_not_found'));
        }
      } catch (error) {
        // Gestion des erreurs comme 500 ou autres
        setIsLoading(false);  // Assurez-vous que le loader s'arrête
        setIsResponse(true);
    
        // Vérifiez si l'erreur contient une réponse HTTP
        if (error?.response?.status === 500) {
          setMessage(t('withdrawal.modal.response.server_error'));
        } else {
          setMessage(t('withdrawal.modal.response.uncaught_error'));
        }
      }
    };
    

    useEffect(() =>{
        setTrackAmountChanges(amount)
    }, [amount])

    const validate = () => {
      let validationErrors = {};
    
      // Vérifie si le mot de passe est vide
      if (!formData.password.trim()) {
        validationErrors.password = t('withdrawal.modal.response.password_required');
      } 
    
      setErrors(validationErrors); // Stocke les erreurs
      return Object.keys(validationErrors).length === 0; // Renvoie `true` si pas d'erreurs
    };
    

    console.log(amount)

    const { t } = useTranslation()
    return(
      <div>
        <div className='panel'>
          <div className="instructions">
            <span className="close" onClick={() => setModalVisible(false)}>&times;</span>
            
          { 
            payementMethod ? 
              (
                trackAmountChanges !== '-1' ? (
                    amount > 500 ? ( // Vérifie si le montant est supérieur à 500
                        (isWithdrawable === 'Yes') ? (
                            <form onSubmit={submit}>
                            <p className="message">
                            <div className="warn">
                            {t('withdrawal.modal.warning')}{' '}
                                <span className="amount">{amount} XAF</span> {t('withdrawal.modal.from_hooyia')}
                            </div>
                            <div className="rule">
                              {t('withdrawal.modal.password_required')}
                            </div>
                            </p>
                            <input
                            autoComplete="new-password" 
                            autoCorrect="off" 
                            autoCapitalize="off" 
                            spellCheck="false"
                            type="password"
                            name="pass"
                            value={formData.password}
                            onChange={(e) => handlePassChange(e.target.value)}
                            required
                            />
                            {errors.password && <p className="error-message">{errors.password}</p>}

                            <button 
                              className="submit_btn" 
                              onClick={submit} 
                              disabled={isLoading}
                              >
                                { !isLoading ? 
                                  'Submit' : 
                                  <span className='loadingButton'><ThreeDots /></span>}
                            </button>

                            <Alert
                              message={message}
                              show={isResponse && message != ''}
                              onClose={() => setIsResponse(false)}
                            />
                        </form>
                        ):(
                            <p className="not_selected error_messge">
                              {t('withdrawal.modal.not_possible_withdrawal')}
                            </p>
                        )
                    ) : (
                      <p className="not_selected error_messge">
                        {t('withdrawal.modal.unsufficient_amount')}
                      </p>
                    )
                ) : (
                  <p className="not_selected error_messge">{t('withdrawal.modal.nothing_selected')}</p>
                )
              )
              :(
                <p>
                  <CiWarning className='warning-icon'/> You must go first to edit a payment method. Do it here.
                </p>
              )
          }

          </div>
        </div>
      </div>
    )
  }

export default Modal
