import idea from "../../../../Assets/img/about/idea.png";
import atom from "../../../../Assets/img/about/atom.png";
import badge from "../../../../Assets/img/about/badge.png";
import learning from "../../../../Assets/img/about/learning.png";
import technical from "../../../../Assets/img/about/technical-support.png";
import leader from "../../../../Assets/img/about/leader.png";

import christian from "../../../../Assets/img/about/christian.jpg";
import boris from "../../../../Assets/img/about/boris.jpg";
import belviane from "../../../../Assets/img/about/belviane2.jpg";
import MrDonald from "../../../../Assets/img/about/donald.jpeg";
 

export const values = (t) => [
    {
        id: 1,
        title: t('about_page.values.item1.title'),
        image: idea,
        content: t('about_page.values.item1.desc')
    },
    {
        id: 2,
        title: t('about_page.values.item2.title'),
        image: learning,
        content: t('about_page.values.item2.desc')
    },
    {
        id: 3,
        title: t('about_page.values.item3.title'),
        image: technical,
        content: t('about_page.values.item3.desc')
    },
    {
        id: 4,
        title: t('about_page.values.item4.title'),
        image: atom,
        content: t('about_page.values.item4.desc')
    },
    {
        id: 5,
        title: t('about_page.values.item5.title'),
        image: badge,
        content: t('about_page.values.item5.desc')
    },
    {
        id: 6,
        title: t('about_page.values.item6.title'),
        image: leader,
        content: t('about_page.values.item6.desc')
    } // Add the missing comma here
];

export const team = [
    {
        id: 1,
        image: MrDonald,
        name: "Tedom Noutchogouin Donald"
    },
    {
        id: 2,
        image: belviane,
        name: "Nogho Tidang Belviane"
    },
    {
        id: 3,
        image: christian,
        name: "Ndongmo Nguimfack Christian"
    },
    {
        id: 4,
        image: boris,
        name: "Fotsing Tchoupe Mathieu Boris"
    }
];
