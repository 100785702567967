import React from 'react';
import { FiDollarSign, FiBook, FiBriefcase } from 'react-icons/fi'
import { useTranslation } from 'react-i18next';


const Header = ({ unitsSold, totalSales, categorySales }) => {

    const { t } = useTranslation();
    return (
        <div className="cards_grid">
            {/*   first item   */}
            <div className="stats_card">
                <div className="flex_title">
                    <h2>{t('statistics.total_sales')}</h2>
                     <div className="icon_1">
                         <FiDollarSign />
                     </div>
                </div>
                <br/>
                <div className="sub_title">
                    {totalSales} XAF
                </div>
            </div>

            {/*    second item    */}
            <div className="stats_card">
                <div className="flex_title">
                    <h2>{t('statistics.books_sold')}</h2>
                     <div className="icon_2">
                         <FiBook />
                     </div>
                </div>
                <br/>
                <div className="sub_title">
                    {unitsSold} {t('statistics.unit')}
                </div>
            </div>

            {/*    third item   */}
            <div className="stats_card">
                <div className="flex_title">
                    <h2>{t('statistics.category_sales')}</h2>
                      <div className="icon_3">
                          <FiBriefcase />
                      </div>
                </div>
                <br/>
                <div className="sub_title">
                    {categorySales} XAF <span>{t('statistics.since_last_month')}</span>
                </div>
            </div>
        </div>
    );
};

export default Header;