import React, {useEffect, useMemo, useState } from 'react';
import './statistics.css'
import Header from "./components/Header";
import { tableData } from "./Data";
import StatisticsFilter from "./components/StatisticsFilter";
import Datatable from "./components/Datatable";
import StatisticsChart from "./components/StatisticsChart";
import { useTranslation } from 'react-i18next';
import { getRequest } from "../../../Resquest";

const SelllerStats = () => {
    const [unitsSold, setUnitSold] = useState(0);
    const [totalSales, setTotalSales] = useState(0);
    const [categorySales, setCategorySales] = useState([]);
    const [monthlyEarnings, setMonthlyEarnings] = useState([]);
    const [ salesByBook, setSalesByBook ] = useState([])

    const getStats = async () => {
        const url = '/seller-statistics/';
        const response = await getRequest(url);
        setTotalSales(response.data.total_sales);
        setUnitSold(response.data.books_sold);
        setCategorySales(response.data.revenue_by_book_category);
        setMonthlyEarnings(response.data.monthly_earnings);
        setSalesByBook(response.data.sales_by_book_title);
        return response;
    }

    useEffect(() => {
           getStats();
    }, []);

    const [filter, setFilter] = useState('all');

    const totalSalesCategory = useMemo(() => {
        if (!categorySales || Object.keys(categorySales).length === 0) return 0;
        // Convert object values to an array and sum them
        return Object.values(categorySales).reduce((sum, value) => sum + value, 0);
    }, [categorySales]);

    const totalEarningsPerMonth = useMemo(() => {
        if (!monthlyEarnings || monthlyEarnings.length === 0) {
            console.warn("monthlyEarnings is empty or undefined", monthlyEarnings);
            return [];
        }

        return monthlyEarnings.map((monthData) => {
            const totalEarned = monthData.weekly_earnings.reduce(
                (sum, week) => sum + (week.total_earned || 0),
                0
            );

            return {
                month: monthData.month,
                totalEarned,
                weeklyEarnings: monthData.weekly_earnings.map((week) => ({
                    week: week.week,
                    totalEarned: week.total_earned || 0,
                })),
            };
        });
    }, [monthlyEarnings]);

    const formatBooksByTitleRevenue = (salesByBook) => {
        if (!salesByBook || Object.keys(salesByBook).length === 0) return [];

        return Object.entries(salesByBook).map(([title, details]) => ({
            title: title,
            quantity: details.quantity,
            price: details.price,
            category: details.category,
            totalSold: details.quantity * details.price,
        }));
    };

    const formattedBooksByTitleData = useMemo(() => {
        return formatBooksByTitleRevenue(salesByBook);
    }, [salesByBook]);

    const filterBooks = (books, filterType) => {
        let sortedData;
        switch (filterType) {
            case 'most':
                sortedData = [...books].sort((a, b) => b.totalSold - a.totalSold); // Create a new array and sort
                break;
            case 'least':
                sortedData = [...books].sort((a, b) => a.totalSold - b.totalSold); // Create a new array and sort
                break;
            default:
                sortedData = books; // Return the original data if no filter is selected
                break;
        }
        return sortedData;
    };

    const filteredDataTable = useMemo(() => {
        return filterBooks(formattedBooksByTitleData, filter);
    }, [formattedBooksByTitleData, filter]);


    const handleFilterChange = (e) => {
        setFilter(e.target.value);
    };


    const { t } = useTranslation();

    return (
        <div className="main_container">
            <h2 className="seller_heading">
                {t('statistics.seller_dashboard')} <span>/ {t('statistics.stats_overview')}</span>
            </h2>
            <Header totalSales={totalSales} unitsSold={unitsSold} categorySales={totalSalesCategory}/>
            <StatisticsChart revenueData={totalEarningsPerMonth} categorySales={categorySales}/>
            <div className="section_flex">
                {/* Books Overview */}
                <h2 className="seller_heading">{t('statistics.book_overview')} </h2>
                {/* Filter UI */}
                <StatisticsFilter filter={filter} onChange={handleFilterChange}/>
            </div>
            <Datatable filteredData={filteredDataTable}/>
        </div>
    );
};

export default SelllerStats;