import React, { useContext } from 'react'
import './App.css';
import AppN from './Components/NON-USER/AppN'
import { UserContext } from './Context/AuthContext';
import AppUser from './App/AppUser';
import 'leaflet/dist/leaflet.css';
import { CookieConsentProvider } from './Context/CookieConsentContext';
function App() {
  const [user, setUser] = useContext(UserContext);

  return (
      <div className="App">
          {
            user?.user 
            ? <AppUser user={user} /> : <AppN />
          }
      </div>
  );
}

export default App;
