import React, {useContext, useState} from 'react'
import { AiFillStar } from 'react-icons/ai';
import './Cart.css'
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";
import { WishListContext } from "../../Context/WishListContext";


const CartItem = ({ product, handleRemove, wishListChanged }) => {
    let medias = product.cover;
    const location = useLocation();
    const isWishlistRoute = location.pathname === '/wishList';

    
    const stars = Array(5).fill(0);
    const colors={
        active: "#F7D000",
        gray: "#a9a9a9"
    }


//     if(product?.fields?.productImage){
//         product?.fields?.productImage.forEach(elt => {
//             medias.push(elt.fields.file.url);
//         });
//     }


//     const {name, productRatings, price} = product?.fields
//     const id = product?.sys?.id;
    const removeItem = () => {
        if (window.location.pathname === "/wishList")
            wishListChanged(product)
        else
            handleRemove(product.slug)
    }








    const { t } = useTranslation()
    return (
        <div>
            <div className="cartItemWrapper">
                <div className="cartFlexItem cartImageWrapper">
                    <img src={medias} alt={ product?.name } />
                </div>
                <div className="cartFlexItem cartDescription">
                    <h3 className="cartItemName">{product.name}</h3>
                    <div className="rating remove">
                        {stars.map((_, index)=>(
                            <AiFillStar key = {index} color={product.avg_rating > index ? colors.active: colors.gray}/>
                        ))}
                        <div className="cartNumbers remove">2123 { t("cart_page.sold") } . { t("cart_page.levels") }</div>
                    </div>
                    <div className="cartMobile_price">{product.price}</div>
                </div>
                <div className="cartFlexItem cartControl">
                    <div className="cartPrice">{product.price}</div>
                    <div className="cartBtnControl">
                        <span onClick={removeItem} className="cartBtn">{ t("cart_page.remove_btn") }</span>
                        {
                            !isWishlistRoute && (
                                <span className="cartBtn cartSaveToLater add">{t("cart_page.add_btn")}</span>
                            )
                        }
                    </div>
                </div>
            </div>

        </div>
    )
}

export default CartItem
