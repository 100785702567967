import React, { useState, useEffect, useLayoutEffect } from 'react';
import { getRequest } from '../../../../Resquest';
import DataTable, {createTheme} from 'react-data-table-component';
import './WithdrawTable.css';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import  withdraw_png from '../../../../Assets/img/withdraw.png';
import { TbReportMoney } from 'react-icons/tb';
import Modal from './Modal';


const WithdrawTable = ({ onDataFetched, payementMethod }) => {
  const [tableType, setTableType] = useState('all');
  const { t } = useTranslation();
  const [months, setMonths] = useState([]);
  const [mappedData, setMappedData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [id, setId] = useState('');
  const [amount, setAmount] = useState(-1);
  const [isWithdrawable, setIsWithdrawable] = useState('');
  const [lastPerformedWithdrawal, setLastPerformedWithdrawal] = useState(null)

  useLayoutEffect(() => {
      const monthsEn = [
          'January', 'February', 'March', 'April', 'May', 'June',
          'July', 'August', 'September', 'October', 'November', 'December'
      ];
      const monthsFr = [
          'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
          'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
      ];
      const lang = i18next.language;
      setMonths(lang === 'en' ? monthsEn : monthsFr);
  }, []);

  useEffect(() => {
      const url = '/seller/ebook/seller-earnings-monthly/';
      getRequest(url).then((res) => {
          if (onDataFetched) {
              onDataFetched(res.data);
          }
          const data = res.data?.results?.map(item => ({
              month: item.month,
              year: item.year,
              amount_earned: item.amount_earned,
              is_withdrawable: item.is_withdrawable,
              is_paid: item.is_paid ? t('withdrawal.withdraw_table.paid') : t('withdrawal.withdraw_table.unpaid'),
              id: item.id
          }));
          setMappedData(data);
          setFilteredData(data); // Initialise avec toutes les données
          const last_paid = data.reverse().find((item) => item.is_paid === t('withdrawal.withdraw_table.paid')) || null;
          setLastPerformedWithdrawal(last_paid)
      });
  }, []);

  const handleFilterChange = (e) => {
      const filterType = e.target.value;
      setTableType(filterType);

      if (filterType === 'all') {
          setFilteredData(mappedData); // Toutes les données
      } else if (filterType === 'is_withdrawable') {
          setFilteredData(mappedData.filter(item => item.is_withdrawable === true));
      } else if (filterType === 'is_not_withdrawable') {
          setFilteredData(mappedData.filter(item => item.is_withdrawable === false));
      } else if (filterType === 'withdrawn') {
          setFilteredData(mappedData.filter(item => item.is_paid === t('withdrawal.withdraw_table.paid')));
      }
  };

  const handleSelectedRowsChange = (state) => {
      if (state.selectedRows.length > 0) {
          setSelectedRows(state.selectedRows);
          setId(state.selectedRows[0].id);
          setAmount(state.selectedRows[0].amount_earned);
          setIsWithdrawable(state.selectedRows[0].is_withdrawable);
      } else {
          setSelectedRows([]);
          setId('');
          setAmount('-1');
          setIsWithdrawable('');
      }
  };


  return (
      <div style={{ padding: '20px' }}>
          <div style={{ marginBottom: '20px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '15px' }}>
              {/* Filter Section */}
              <div className='earning_history filter'>
                  <label htmlFor="filter" className='filter_label'>{t('withdrawal.withdraw_table.filter')}</label>
                  <select
                      className='filter_tree_select'
                      value={tableType}
                      onChange={handleFilterChange}
                      id="filter"
                      style={{
                          border: '1px solid #ccc',
                          borderRadius: '5px',
                          marginLeft: '10px',
                      }}
                  >
                      <option value="all">{t('withdrawal.withdraw_table.all')}</option>
                      <option value="is_withdrawable">{t('withdrawal.withdraw_table.withdrawable')}</option>
                      <option value="is_not_withdrawable">{t('withdrawal.withdraw_table.not_withdrawable')}</option>
                      <option value="withdrawn">{t('withdrawal.withdraw_table.already_withdrawn')}</option>
                  </select>
                  {/* Withdrawal Button */}
                  <button
                      className={`withdraw_btn ${selectedRows[0] === undefined ? 'disabled_btn' : ''}`}
                      onClick={() => setModalVisible(true)}
                  >
                      <span><img src={withdraw_png} alt="withdrawal-icon" width={25} height={25} /></span>
                      <p>{t('withdrawal.withdraw_table.withdraw_now')}</p>
                  </button>
                  {modalVisible && (
                      <Modal
                          setModalVisible={setModalVisible}
                          id={id}
                          amount={amount ? amount : '---'}
                          isWithdrawable={isWithdrawable}
                          payementMethod={payementMethod}
                      />
                  )}
              </div>
              <div className="last_withdrawal">
                  {t('withdrawal.withdrawal_board.last_withdrawal')} : &nbsp;
                  {lastPerformedWithdrawal ? (
                    <span>
                         {months[lastPerformedWithdrawal - 1]},  {lastPerformedWithdrawal.amount_earned} XAF
                    </span>
                ) : (
                    <span>{t('withdrawal.withdraw_table.no_paid')}</span>
                )} 
                <TbReportMoney />
              </div>
          </div>

          {/* History Pane */}
          <div className="responsive-table-wrapper">
              <DataTable
                  title="Earnings table"
                  columns={[
                      {
                          name: <div className='row_item row_item_header'>{t('withdrawal.withdraw_table.month')}</div>,
                          selector: row => <div className='row_item'>{months[row.month - 1]} </div>,
                          sortable: true,
                      },
                      {
                          name: <div className='row_item row_item_header'>{t('withdrawal.withdraw_table.year')}</div>,
                          selector: row => <div className='row_item'>{row.year} </div>,
                          sortable: true,
                      },
                      {
                          name: <div className='row_item row_item_header'>{t('withdrawal.withdraw_table.amount_earned')}</div>,
                          selector: row => <div className='row_item'>{row.amount_earned} </div>,
                          sortable: true,
                      },
                      {
                          name: <div className='row_item row_item_header'>{t('withdrawal.withdraw_table.withdraw_status')}</div>,
                          selector: row => <div className='row_item'>{row.is_withdrawable ? 'Yes' : 'No'} </div>,
                      },
                      {
                          name: <div className='row_item row_item_header'>{t('withdrawal.withdraw_table.payment_status')}</div>,
                          selector: row => <div className={`row_item ${row.is_paid == 'Paid' ? 'paid': 'unpaid'} `}>{row.is_paid} </div>,
                      },
                  ]}
                  data={filteredData} // Utilise les données filtrées
                  pagination
                  striped
                  selectableRows
                  selectableRowsSingle
                  onSelectedRowsChange={handleSelectedRowsChange}
                  selectableRowsHighlight
                  paginationPerPage={5}
                  paginationRowsPerPageOptions={[5, 10, 15]}
                  highlightOnHover
                  fixedHeader
                  fixedHeaderScrollHeight="400px"
                  responsive
              />
          </div>
      </div>
  );
};

 
export default WithdrawTable

