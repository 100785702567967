import React, { useLayoutEffect, useState, useEffect } from 'react'
import './Profile.css'
import './StoreProfile.css'
import { getRequest } from '../../Resquest'
import ProductGrid from '../Products/ProductGrid'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { tabTitle } from '../..'
import facebook from '../../Assets/img/facebook.png'
import twitter from '../../Assets/img/twitter.png'
import linkedin from '../../Assets/img/linkedin.png'
import { FaLink } from 'react-icons/fa'
import { AnimatePresence } from 'framer-motion'
import { CopyAlert } from '../SELLER/Dashboard/Components/ListBooks'
import { AWSbaseURL } from '../../Resquest'

const StoreProfile = ({ user, username }) => {
        const [userData, setUserData] = useState({})
        const [isloading, setIsloading] = useState(false)
        const [ebooks, setEbooks] = useState([])
        const [originalEbooks, setOriginalEbooks] = useState([])
        const [fname, setFname] = useState('')
        const [lname, setLname] = useState('')
        const [searchString, setSearchString] = useState('')
        const [lang, setLang] = useState('en')
	const [copiedStore, setCopiedStore] = useState(false)

        useLayoutEffect(() => {
            setIsloading(true)
            get_store()
            getLang()
        }, [])

        useEffect(() => {
                const filterSearch = () => {
                        if (searchString) {
                          setEbooks(
                            originalEbooks.filter((book) =>
                              book.name.toLowerCase().includes(searchString.toLowerCase())
                            )
                          );
                        } else {
                          setEbooks(originalEbooks); // Réinitialiser la liste si le champ est vide
                        }
                };
                filterSearch();
        },[searchString, originalEbooks])
        const getLang = () => {
                setLang(i18next.language)
        }

        const get_store = () => {
                const username = window.location.pathname.split('/me/')[1]
                let endpoint = "/ebook-store/"+ username;
                request(endpoint)
        }


        const request = async(endpoint) => {
                const res = await getRequest(endpoint)
                res.status === 200 && saveResponse(res.data)
        }
            
        const saveUserData = ( user) => {
            setFname(user.name.split(' ')[0])
            setLname(user.name.split(' ')[1])
        }

        const saveResponse = (data) => {
            console.log('value of datas : ', data)
            saveUserData(data.user)
            setUserData(data)
            setEbooks(data?.ebooks)
            setOriginalEbooks(data?.ebooks)
            setIsloading(false)
        }
	const copyLink = () => {
		const link =  user.user.seller_ebook_shop_url
		navigator.clipboard.writeText(link)
		setCopiedStore(true)
		setTimeout(() => {
			setCopiedStore(false)
		}, 3000);
	}
        tabTitle(`${fname} ${lname} HooYia store`)
        
        const { t } = useTranslation()
        return (
    <div className='personal_shop_space'>
        <div class="top-container">
                <div class="containers">
                        <div class="seller_header">
                                {
                                        userData?.user
                                        ?
                                        <>
                                                <div class="bloc_image">
                                                        {
                                                                userData?.user?.pp
                                                                ?
                                                                <img class="profile_image" src={AWSbaseURL + (userData?.user?.pp)} alt="Profile image"/>
                                                                :
                                                                <p className='profile_image'>{ (fname).charAt(0)} { (lname).charAt(0)  }</p>
                                                        }
                                                </div>
                                                <div class="infos">
                                                        {
                                                                <>
                                                                        <p class="welcome">
                                                                               {
                                                                                        lang === 'en'
                                                                                        ?
                                                                                        (<>Welcome to <span class="name">{fname} {lname}</span>'s shop space !</> )
                                                                                        :
                                                                                        (<>Bienvenue dans la boutique HooYia de <span class="name">{fname} {lname}</span> !</>)
                                                                                        
                                                                               }
                                                                        </p>
                                                                        <p className='seller_address'>{t('store_profile.address')} { userData?.user?.city + ',' ?  userData?.user?.city : ''}  { userData?.user?.country ?  userData?.user?.country : '----'  }</p>
                                                                        <p class="date">{userData?.user?.email}</p>
                                                                </>
                                                        }
                                                </div>
                                        </>
                                        :
                                        <div className='container flex_profile_head'>
                                                <h3>{ t("randoms.store_not_found") }</h3>
                                        </div>
                                }
                        </div>
                        <div class="about_and_stats">
                                <div class="bio_seller">
                                        <h2 class="title_about">{t('store_profile.about')} {fname} :</h2>
                                        <p class="contain">
                                        {userData?.user?.bio ? userData?.user?.bio : '--------------'}
                                        </p>
                                </div>
                                <div class="expertise">
                                        <div class="book">
                                                <span class="item_title">{t('store_profile.books')} </span><span class="item_value">{ebooks ? ebooks.length : '----'}{t('store_profile.items')} </span>
                                        </div>
                                        <div class="book">
                                                <span class="item_title">{t('store_profile.country')} </span> { <span class="item_value"> {userData?.user?.country} </span>}
                                        </div>
                                        <div>
                                                <span class=" item_title follow_section">{t('store_profile.follow_me_on')} :</span> 
                                                <div className='share_links'>
								<ul>
									<li className="d_flex_link">
									<a href={"https://www.facebook.com/sharer/sharer.php?u=" + user.user.seller_ebook_shop_url} target="_blank">{ t("share.fb") }</a>
										<img src={facebook} width={20} height={20}/>
									</li>
									<li className="d_flex_link">
										<a href={"https://www.linkedin.com/sharing/share-offsite/?url=" + user.user.seller_ebook_shop_url} target="_blank">{ t("share.linkedin") }</a>
										<img src={linkedin} width={22} height={22}/>
									</li>
									<li className="d_flex_link">
										<a href={"https://twitter.com/intent/tweet?url=YOUR_URL_HERE&text=" + user.user.seller_ebook_shop_url}
										   target="_blank">{t("share.tweeter")}</a>
										<img src={twitter} width={20} height={20}/>
									</li>
									<li onClick={copyLink} className='copy_store'>
										<span>{ t("share.copy") }</span>
										<span><FaLink /></span>
									</li>
								</ul>
							</div>
                                        </div>
                                </div>
                        </div>
                </div>
        </div>

        {
                userData?.user && (
                        <>
                                <div className='profile_body'>
                                        <div className='container'>
                                                <h3 className='book_collection_container' style={{ marginTop: '0px' }}>{ t("alerts.store_title") }</h3>
                                                <div className='filter_store_wrapper'>
                                                        <span className='filter_heading filter_text'>{t('store_profile.filter')} </span>
                                                        <input type='text' disabled={ebooks.length <= 0 } className='filter_store_input filter_select' onChange={e => setSearchString(e.target.value)} value={searchString} placeholder='search...' />
                                                </div>
                                                {
                                                        ebooks.length > 0
                                                        ?
                                                        <ProductGrid products={ebooks} isloading={isloading} />
                                                :
                                                        <div>{t("no_books_to_show")}</div>
                                                }
                                        </div>
                                </div>
                        
                        </>
                )
        }
        	<AnimatePresence
			initial={false}
			mode='wait'
		>
			{ copiedStore && (
				<CopyAlert />
			)}
		</AnimatePresence>
    </div>
  )
}

export default StoreProfile
