import React from 'react';


const CustomMap = () => {
    // Coordinates for the marker (latitude, longitude)
    const latitude = 5.470572171264805;
    const longitude = 10.43339343550243;

    return (
        <div style={{ width: '100%', height: '600px' }}>
            <iframe
                src={`https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d756.3192825502849!2d${longitude}!3d${latitude}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e1!3m2!1sen!2scm!4v1730883319921!5m2!1sen!2scm&markers=${latitude},${longitude}`}
                width="1200"
                height="600"
                style={{ border: '0' }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
        </div>
    );
}

export default CustomMap;
