import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { IsSellercontext } from '../../Context/IsSellerContext'
import { useLocation } from 'react-router-dom'


const InfoSwitchBuying = () => {
    const { t } = useTranslation()
    const [isSellerAccount, setIsSellerAccount] = useContext(IsSellercontext)
    const location = useLocation();
    const { path } = location.state;

    const switch_to_buying = () => {
        setIsSellerAccount(false)
        window.location.href = path
    }
  return (
    <div className='container switch_dashboard_page'>
        <h2>{ t("switch.title") }</h2>
        <button onClick={switch_to_buying}>{ t("switch.btn") } </button>
    </div>
  )
}

export default InfoSwitchBuying