import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react'
import Select from 'react-select'


const CategorySelect = ({ categoryList, handleCategorySelect }) => {
        const [value, setValue] = useState(categoryList[0])
        const [selectedOption, setSelectedOption] = useState([]);
        const [options, setOptions] = useState([]);
    
        const changeHandler = value => {
                setValue(value)
                handleCategorySelect(value);

        }
        useLayoutEffect(() => {
                categoryList.forEach(element => {
                        setSelectedOption( list => [...list, {"name": element.name, "label": element.name, "uuid": element.pk}])
                        
                });
        }, []);
        

        return (
                <>
                        <Select defaultValue={selectedOption} options={selectedOption} value={value} onChange={changeHandler} className="custom-category-dropdown" />
                </>
        )
}

export default CategorySelect
