// CookieConsentContext.js

import React, { createContext, useContext, useState, useEffect } from 'react';
import {  useTranslation } from 'react-i18next';
import { t } from 'i18next';
// Création du contexte
const CookieConsentContext = createContext();

// Hook pour utiliser le contexte facilement dans d'autres composants
export const useCookieConsent = () => useContext(CookieConsentContext);

// Fournisseur de contexte
export const CookieConsentProvider = ({ children }) => {
  const [isConsentGiven, setIsConsentGiven] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (consent) {
      setIsConsentGiven(true);
    }
  }, []);

  const giveConsent = () => {
    localStorage.setItem('cookieConsent', 'true');
    setIsConsentGiven(true);
  };

  const revokeConsent = () => {
    localStorage.removeItem('cookieConsent');
    setIsConsentGiven(false);
  };
  const { t } = useTranslation();

  return (
    <CookieConsentContext.Provider value={{ isConsentGiven, giveConsent, revokeConsent }}>
      {children}
      {!isConsentGiven && <CookieConsentBanner />}
    </CookieConsentContext.Provider>
  );
};

// Composant de bannière pour afficher le consentement aux cookies
const CookieConsentBanner = () => {
  const { giveConsent } = useCookieConsent();

  return (
    <div style={styles.banner}>
      <p style={styles.message}>
        {t('landing.privacy_policy')} <a href="/privacy-policy" style={styles.link}>{t('landing.policy_text')}</a>.
      <button onClick={giveConsent} style={styles.button}>
        {t('landing.accept_policy')}
      </button></p>

    </div>
  );
};

const styles = {
  banner: {
    position: 'fixed',
    width: '100%',
    backgroundColor: '#333',
    color: '#fff',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 20px',
    zIndex: 1000,
    margin: 'auto',
    height: 'auto',
    padding: '2rem',
    bottom: 0,
  },
  message: {
    margin: 0,
    textWrap: 'wrap',
  },
  link: {
    color: '#4CAF50',
    textDecoration: 'underline',
  },
  button: {
    backgroundColor: '#4CAF50',
    color: 'white',
    border: 'none',
    marginLeft: '10px',
    padding: '8px 16px',
    cursor: 'pointer',
    borderRadius: '4px',
    textAlign: 'center',
  },
};

export default CookieConsentContext;
