import React from 'react'
import CustomMap from './Map'
import { useTranslation } from 'react-i18next'

const LocationSection = () => {
  const { t } = useTranslation()
  return (
    <div className='landing_page_location_section'>
        <h2 className='section_title '>{ t('about_page.location') }</h2>
        <div className='location_google_map container'>
            <CustomMap />
        </div>
    </div>
  )
}

export default LocationSection