import React, { useContext, useEffect, useState } from 'react'

import './SellerProfile.css'

import DefaultProfilePic from '../../../../Assets/img/personPlaceholder.jpg'
import { Link, useNavigate } from 'react-router-dom'
import { IoPencilOutline } from 'react-icons/io5'
import { VscPerson } from 'react-icons/vsc'
import { AiOutlineBank } from 'react-icons/ai'
import { SlBadge } from 'react-icons/sl'
import { TfiBook } from 'react-icons/tfi'
import { useTranslation } from 'react-i18next'
import { AWSbaseURL } from '../../../../Resquest'
import { ReactComponent as ThreeDots } from '../../../../Assets/Loaders/ThreeDots.svg'
import VerifySeller from '../Components/VerifyUser'
import MyBooks from '../../../Products/MyBooks'
import { useStats } from 'react-instantsearch'
import useLocalStorage from '../../../lib/UseLocalStorage'
import { getAllBanks, getCountry, getRequest, patchRequest } from '../../../../Resquest'
import SellerBook from './SellerBook'
import { FiLoader } from 'react-icons/fi'
import NoBooks from '../Components/NoBooks'
import LoadingPage from '../Components/LoadingPage'
import axios from 'axios'
import { UserContext } from '../../../../Context/AuthContext'
import { BsChevronDown } from 'react-icons/bs'
import { BsCameraFill } from 'react-icons/bs'
import { isEditable } from '@testing-library/user-event/dist/utils'
import InfoToast from '../../../Addons/Toast/InfoToast'
import ErrorToast from '../../../Addons/Toast/ErrorToast'
import WarningToast from '../../../Addons/Toast/WarningToast'
import SuccessToast from '../../../Addons/Toast/SuccessToast'

const SellerProfile = ({ user }) => {
	const [ updateUser, setUpdateUser ] = useLocalStorage('user');
	const [profileTab, setProfileTab] = useState('personal_info');
	const [User, setUser] = useContext(UserContext);
	const [ isNameEditable, setIsNameEditable] = useState(false);
	const [profilePic, setProfilePic] = useState(null);
	const [ fname, setFName ] = useState(user?.user?.first_name);
	const [ lname, setLName ] = useState(user?.user?.last_name);
	const [email, setEmail] = useState(user?.user?.email);
	const [showModal, setShowModal] = useState(false)
	const [ message, setMessge]= useState('')
	const { t } = useTranslation();

	const data = {
		// "user": User?.user,
		"first_name": fname,
		"last_name": lname,
		"email": email

	};
	const saveEditName = async () => {
		try {

			// Appel API avec axios
			const resp = await patchRequest(`https://hooyia.herokuapp.com/api/v1/auth/seller-profile/${user?.user?.profile?.pk}/`, data);
	
			// Met à jour les données utilisateur dans le contexte
			if (resp === 200) {
				setUser({...User,
					user: resp.data?.user})
				console.log('Mise à jour réussie des champs !');
				console.log('response de la mise à jour : ', resp.data)
			} else if(resp === 404 || resp === 402 || resp === 403 || resp === 404){
				console.error("Les données retournées par l'API ne contiennent pas les champs attendus.");
			}
			else{
				console.error('server error')
			}
	
			// Désactive le mode édition
			setIsNameEditable(!isEditable);
		} catch (error) {
			console.error("Erreur lors de la mise à jour des informations :", error);
			setIsNameEditable(!isEditable)
		}
	};
	
	
	useEffect(() => {
		setFName(User?.user?.first_name)
		setLName(User?.user?.last_name)
		setEmail(User?.user?.email)
		console.log('value of user in the localStorage : ', updateUser)
		console.log('value of user in the userContext : ', User)
	}, [])
	/**
	 * Receives input field param and saves
	 * @param { Object } event param from input field
	 */
	const handleFileInput = (event) => {
		const file = event.target.files[0];
		if (file && file.type.startsWith('image/')) {
			if (file.name.includes(".png") || file.name.includes(".jpeg") || file.name.includes(".jpg")) {
				setProfilePic(file)
			}
		}
	};

	const save_profile_pic = async() => {
		const formData = new FormData()
		formData.append('pp', profilePic, profilePic?.name)
		const resp = await axios.patch(`https://hooyia.herokuapp.com/api/v1/auth/seller-profile/${user?.user?.profile?.pk}/`, formData, {
			headers: {
			  'Content-Type': 'multipart/form-data',
			  "Authorization": `Bearer ${user?.access}`
			}
		})
		updatePPField(resp?.data?.pp);
	}


	const updatePPField = (profile) => {
		setUser(prevState => ({
		  ...prevState,
		  user: {
			...prevState.user,
			profile: {
			  ...prevState.user.profile,
			  pp: profile,
			},
		  },
		}));
		setProfilePic(null)
	};
	  
	const handleChangeEdit = () => {
		setIsNameEditable(!isNameEditable);
	}



	return (
		<div className='seller_profile'>
			<div className='container flex'>
				<div className='seller_profile_right'>
					<div className='profilePic'>
						<span className='img_edit_icon'><BsCameraFill/></span>
						{
							profilePic 
							?
								<img src={URL.createObjectURL(profilePic)} alt='profile_pic_import' />
							:
							user?.user?.profile?.pp
								?
								<img src={AWSbaseURL+(user?.user?.profile?.pp)} alt='profile picture' />
								:
								<img src={DefaultProfilePic} alt='profile picture profile_pic_import' />
						}
						
						<input
							className='seller_profile_pic_update'
							type='file' accept='image/jpeg, image/png, image/jpg'
							onChange={e => handleFileInput(e)}
						/>

					</div>
					{ profilePic && <span onClick={save_profile_pic} className='save_profile_update'>{ t("dashboard_edit.profile_edit") }</span> }
					<div className='profile_text'>
						{ 
							isNameEditable 
							?
							<div className=' name_editing_form'>
							< input 
								type='text'
								name='first_name'
								className='name_editing_form_item'
								value={fname}
								onChange={e => setFName(e.target.value)}
								placeholder='enter your first name'

							/>
							< input 
								type='text'
								name='last_name'
								className='name_editing_form_item'
								value={lname}
								onChange={e => setLName(e.target.value)}
								placeholder='enter your last name'

							/>
							<input 
								type="email"
								name='email'
								className='name_editing_form_item'
								value={email}
								onChange={e => setEmail(e.target.value)}
								placeholder='enter your email'
							/>
								<button  type="submit" onClick={() => saveEditName()} className='save_btn'>Save</button>
							</div>
							:
							<div>
								<div className='title name_and_edit_field'>{user?.user?.first_name}  {user?.user?.last_name}</div>
								<p> {user?.user?.email}</p>
								<span onClick={handleChangeEdit} className='edit_profile edit_icon'><IoPencilOutline /></span>

								{/* <WarningToast/> */}
								{/* <ErrorToast/> */}
								{/* <InfoToast/> */}
							</div>
						}

					</div>
				</div>
				<div className='seller_profile_main'>
					<div className='seller_profile_head'>
						<div onClick={() => setProfileTab('personal_info')} className={`seller_profile_head_one ${profileTab === 'personal_info' ? 'active' : ''}`}>
							<span className='seller_dashboard_mobile'><VscPerson /> </span>
							<span className='mobile_seller_dashboard'>Me</span>
							<span className='desktop'>{ t("dashboard_edit.personal") }</span>
						</div>
						<div onClick={() => setProfileTab('payment_details')} className={`seller_profile_head_one ${profileTab === 'payment_details' ? 'active' : ''}`}>
							<span className='seller_dashboard_mobile'><AiOutlineBank /> </span>
							<span className='mobile_seller_dashboard'>Pay</span>
							<span className='desktop'>{ t("dashboard_edit.payment") }</span>
						</div>
						<div onClick={() => setProfileTab('achievements')} className={`seller_profile_head_one ${profileTab === 'achievements' ? 'active' : ''}`}>
							<span className='seller_dashboard_mobile'><SlBadge /> </span>
							<span className='mobile_seller_dashboard'>Ach..</span>
							<span className='desktop'>{ t("dashboard_edit.achievement") }</span>
						</div>
						<div onClick={() => setProfileTab('my_books')} className={`seller_profile_head_one ${profileTab === 'my_books' ? 'active' : ''}`}>
							<span className='seller_dashboard_mobile'><TfiBook /> </span>
							<span className='mobile_seller_dashboard'>{ t("dashboard_edit.book") }</span>
							<span className='desktop'>{ t("dashboard_edit.my_books") }</span>
						</div>
					</div>
					{
						profileTab === 'personal_info'
							?
							<PersonalInfo />
							:
							profileTab === 'payment_details'
								?
								<PaymentDetails />
								:
								profileTab === 'achievements'
									?
									<Achievements />
									:
									profileTab === 'my_books'
										?
										<MySellingBooks />
										:
										<PersonalInfo />
					}
				</div>
			</div>
		</div>
	)
}

export default SellerProfile


const PersonalInfo = () => {
	const [user, setuser] = useLocalStorage('user')
	const [editing, setEditing] = useState(false);
	const [country, setCountry] = useState('');
	const [countryList, setCountryList] = useState()
	const [countryListCopy, setCountryListCopy] = useState([])
	const [countryDropdownState, setCountryDropdownState] = useState(false)
	const [selectedCountry, setSelectedCountry] = useState({})

	const [city, setCity] = useState('');
	const [address_one, setAddress_one] = useState('');
	const [address_two, setAddress_two] = useState('CCAST Street');

	const [phone_one, setPhone_one] = useState('+237 678 234 567');
	const [phone_two, setPhone_two] = useState('+237 678 234 567');
	const [bio, setBio] = useState('Tell us about yourself...');
	const [bankList, setBankList] = useState([])

	const [ showModal, setShowModal] = useState(false)
	const [ message, setMessge ] = useState('')

	const [isloading, setIsloading] = useState(false)
	const data = {
		"user": user?.user,
		"country": selectedCountry.pk,
		"city": city,
		"address_line_one": address_one,
		"address_line_two": address_two,
		"main_phone_number": phone_one,
		"alt_phone_number": phone_two,
		"bio": bio
	}

	const saveEdit = async () => {
		setIsloading(true)
		const res = await patchRequest(`https://hooyia.herokuapp.com/api/v1/auth/seller-profile/${user?.user?.profile?.pk}/`, data)
		// console.log(res);
		setIsloading(false)
		setEditing(false)
		if(res.status = 200){
		setuser({
			...user,
			user: res.data?.user,
		})
		setMessge('successfully updated !');
		setShowModal(true)
	}
	}
	useEffect(() => {
		const profile = user?.user?.profile
		setCountry(profile?.country)
		setCity(profile?.city)
		setAddress_one(profile?.address_line_one)
		setAddress_two(profile?.address_line_two)
		setPhone_one(profile?.main_phone_number)
		setPhone_two(profile?.alt_phone_number)
		setBio( profile?.bio )
		fetchCountry()
		console.log('value of user : ', user);
	}, [])
	

	if (countryList) {
		// console.log('country list', countryList)
		countryList.forEach(count => {
			count.pk == country &&
				setCountry(count.name)
		})
	}

	useEffect(() => {
		fetchCountry()
		getBanks()
		console.log(bankList)
	}, [])
	

	/**
	 * Request list of countries from the backend
	 */
	const fetchCountry = async() => {
		const response = await getCountry();
		const res = response.data?.results
		setCountryList(res)
		setCountryListCopy(res);
	}

	
	/**
	 * Request bank information from the backend
	 */
	const getBanks = async() => {
		const response = await getAllBanks();
		response.status === 200 && setBankList(response.data.results)
		console.log(" value of getAllBank() method", response)
	}

	const searchCountryList = (e) => {
		// console.log('list of countries', countryList);
		setCountry(e.target.value)
		let remainder = countryListCopy.filter(country => country?.name.toLowerCase().startsWith(e.target.value.toLowerCase()))
		setCountryList(remainder)
	}

	const selectCountry = (country) => {
		setCountry(country?.name)
		setSelectedCountry(country)
		setCountryDropdownState(false)
	}



	const { t } = useTranslation()
	return (
		<div className='seller_profile_dashboard_personal_info'>
			{
				!editing
					? (
						<>
							<div className='seller_profile_wrapper'>
								<div className='profile'>
									<h4 className='seller_title'>{ t("dashboard_edit_form.country") }</h4>
									<p className='input_seller_text'>{country}</p>
								</div>
								<div className='profile'>
									<h4 className='seller_title'>{ t("dashboard_edit_form.city") }</h4>
									<p className='input_seller_text'>{city}</p>
								</div>
							</div>
							<div className='seller_profile_wrapper'>
								<div className='profile'>
									<h4 className='seller_title'>{ t("dashboard_edit_form.address_one") }</h4>
									<p className='input_seller_text'>{address_one}</p>
								</div>
								<div className='profile'>
									<h4 className='seller_title'>{ t("dashboard_edit_form.address_two") }</h4>
									<p className='input_seller_text'>{address_two}</p>
								</div>
							</div>
							<div className='seller_profile_wrapper'>
								<div className='profile'>
									<h4 className='seller_title'>{ t("dashboard_edit_form.phone_one") }</h4>
									<p className='input_seller_text'>{phone_one}</p>
								</div>
								<div className='profile'>
									<h4 className='seller_title'>{ t("dashboard_edit_form.phone_two") }</h4>
									<p className='input_seller_text'>{phone_two}</p>
								</div>
							</div>
							<div className='profile profile_about'>
								<h4 className='seller_title'>{ t("dashboard_edit_form.about_me") }</h4>
								<p>{bio}</p>
							</div>

							<div className='button_payment_request'>
								<button onClick={() => setEditing(true)} className='profile_control_btn'>
									<span>{ t("dashboard_bookcreate.state_edit") }</span>
								</button>
							</div>

						</>
					) : (
						<>
							<div className='seller_profile_wrapper'>
								<div className='profile'>
									<h4 className='seller_title'>{ t("dashboard_edit_form.country") }</h4>
									<input 
										className='input_seller_text' 
										value={country} 
										onChange={e => searchCountryList(e)}
										onBlur={() =>  setTimeout(() => { setCountryDropdownState(false) }, 500)}
										onFocus={() => setCountryDropdownState(true)}  
										placeholder='Select any country' 
										autoComplete='off'
									/>
									<div className={`countrySelectDropDown ${countryDropdownState ? 'showCountryDropDown' : ''}`}>
										{
											countryList && countryList.map(element => (
												<div key={element?.pk} onClick={() => selectCountry(element)} className='nationality_dropdownlist'>
													<span className={`flag flag-icon flag-icon-cm`}></span>
													<span className='countryName'>{ element?.name }</span>
												</div>
											))
										}
									</div>
								</div>
								<div className='profile'>
									<h4 className='seller_title'>{ t("dashboard_edit_form.city") }</h4>
									<input className='input_seller_text' value={city} onChange={(e) => setCity(e.target.value)} placeholder='State or town' />
								</div>
							</div>
							<div className='seller_profile_wrapper'>
								<div className='profile'>
									<h4 className='seller_title'>{ t("dashboard_edit_form.address_one") }</h4>
									<input className='input_seller_text' value={address_one} onChange={(e) => setAddress_one(e.target.value)} placeholder='House address 1' />
								</div>
								<div className='profile'>
									<h4 className='seller_title'>{ t("dashboard_edit_form.address_two") }</h4>
									<input className='input_seller_text' value={address_two} onChange={(e) => setAddress_two(e.target.value)} placeholder='House address 2' />
								</div>
							</div>
							<div className='seller_profile_wrapper'>
								<div className='profile'>
									<h4 className='seller_title'>{ t("dashboard_edit_form.phone_one") }</h4>
									<input className='input_seller_text' value={phone_one} onChange={(e) => setPhone_one(e.target.value)} placeholder='+237 671 234 567' />
								</div>
								<div className='profile'>
									<h4 className='seller_title'>{ t("dashboard_edit_form.phone_two") }</h4>
									<input className='input_seller_text' value={phone_two} onChange={(e) => setPhone_two(e.target.value)} placeholder='+237 671 234 567' />
								</div>
							</div>
							<div className='profile profile_about'>
								<h4 className='seller_title'>{ t("dashboard_edit_form.about_me") }</h4>
								<textarea onChange={(e) => setBio(e.target.value)} className='profile_about_text_input'>{bio}</textarea>
							</div>
							<div className='button_payment_request'>
								<button onClick={() => saveEdit()} className='profile_control_btn'>
									<span className='text'>{ t("dashboard_edit_form.save") }</span>
									{
										isloading && <span className='loading'><ThreeDots /></span>
									}

								</button>
							</div>
							<SuccessToast mmessage={message} />

						</>
					)
			}



		</div>
	)
}

const PaymentDetails = () => {

	const [user, setuser] = useLocalStorage('user')
	const [validated, setValidated] = useState(false);
	const [showPays, setShowPays] = useState(true);
	const [editing, setEditing] = useState(false);

	const [payPal, setPayPal] = useState('');
	const [error, setError] = useState('');

	const [bank, setBank] = useState('');
	const [swift, setSwift] = useState('');
	const [isloading, setIsloading] = useState(false)
	const [phone, setPhone] = useState('')

	const [bankList, setBankList] = useState([])
	const [bankListCopy, setBankListCopy] = useState([])
	const [bankDropdownState, setBankDropdownState] = useState(false)

	const [bankName, setBankName] = useState('')
	const [selectedBank, setSelectedBank] = useState({})

	const data = {
		"paypal_email_address": payPal ? payPal : "",
		"swift": swift ? swift : "",
		"bank": selectedBank?.pk ? selectedBank?.pk : "",
		"phone_one": phone,
		"name_on_account_number": bankName
	}
	useEffect(() => {
		const profile = user?.user?.profile
		setPayPal(profile?.paypal_email_address)
		setBank(profile?.bank__name)
		console.log(user?.user.profile?.bank__name)
		setPhone(profile?.main_phone_number)
		setBankName(profile?.name_on_account_number)
		setSwift(profile?.swift)
		fetchBanks()
		// console.log('bank list value : ',bankList)
	}, [])

	const requestEdit = () => {
		setValidated(true)
	}

	/**
	 * Request list of banks from the backend
	 */
	const fetchBanks = async() => {
		const response = await getAllBanks();
		// console.log('value of response', response)
		const res = response.data?.results
		setBankList(res);
		setBankListCopy(res)
	}

	const searchBankList = (e) => {
		setBank(e.target.value)

		let remainder = bankListCopy.length > 0 && bankListCopy.filter(country => country?.name.toLowerCase().startsWith(e.target.value.toLowerCase()))
		setBankList(remainder)
	}

	const selectBank = (bank) => {
		setBank(bank?.name)
		setSelectedBank(bank)
		setBankDropdownState(false)
	}

	const request = () => {
		setShowPays(true)
	}
	const removeModal = () => {
		setValidated(false)
	}

	const handleChangePaypal =  (e) => {
		setPayPal(e.target.value)
		validatePaypal(e.target.value)
	}

	const saveEdit = async () => {
		setIsloading(true)
		console.log('value of data', data)
		const res = await patchRequest(`https://hooyia.herokuapp.com/api/v1/auth/seller-profile/${user?.user?.profile?.pk}/`, data)
		console.log('value of res', res);
		setIsloading(false)
		setEditing(false)
		res.status = 200 &&
		setuser({
			...user,
			user: res.data?.user,
		})

	}
    const validatePaypal = (value) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(value)) {
            setError('Invalid email address !');
        } else {
            setError('');
        }
    };

	const { t } = useTranslation()
	return (
		<>
			{
				showPays ? (
					<>
						{
							!editing
								? (
									<>
										<div className='seller_profile_wrapper'>
											<div className='profile'>
												<h4 className='seller_title'>{ t("dashboard_edit_form.paypal") } </h4>
												<p className='input_seller_text'>{payPal}</p>
											</div>
											<div className='profile'>
												<h4 className='seller_title'>{ t("dashboard_edit_form.mobile_money") } </h4>
												<p className='input_seller_text'>{phone}</p>
											</div>
										</div>
										<div className='seller_profile_wrapper'>
											<div className='profile'>
												<h4 className='seller_title'>{ t("dashboard_edit_form.name_on_account") }</h4>
												<p className='input_seller_text'>{bankName}</p>
											</div>
											<div className='profile'>
												<h4 className='seller_title'>{ t("dashboard_edit_form.bank") }</h4>
												<p className='input_seller_text'>{bank}</p>
											</div>
										</div>
										<div className='seller_profile_wrapper'>
											<div className='profile'>
												<h4 className='seller_title'>{ t("dashboard_edit_form.swift") }</h4>
												<p className='input_seller_text'>{swift}</p>
											</div>
										</div>
										<div className='button_payment_request'>
											<button onClick={() => setEditing(true)} className='profile_control_btn'>
												<span>{ t("dashboard_edit_form.edit") }</span>
											</button>
										</div>

									</>
								) : (
									<>
										<div className='seller_profile_wrapper'>
											<div className='profile'>
												<h4 className='seller_title'>{ t("dashboard_edit_form.paypal") }</h4>
												<input autoFocus className='input_seller_text' value={payPal} onChange={handleChangePaypal} placeholder='johndoe@mailingserver.com' />
												{error && <p style={{ color: 'red' }}>{error}</p>}
											</div>
											<div className='profile'>
												<h4 className='seller_title'>{ t("dashboard_edit_form.mobile_money") }</h4>
												<input autoFocus className='input_seller_text' value={phone} onChange={(e) => setPhone(e.target.value)} placeholder='+237 123 456 789' />
											</div>
										</div>
										<div className='seller_profile_wrapper'>
											<div className='profile'>
												<h4 className='seller_title'>{ t("dashboard_edit_form.name_on_account") }</h4>
												<input className='input_seller_text' value={bankName} onChange={(e) => setBankName(e.target.value)} placeholder= {bank} />
											</div>

											<div className='profile'>
												<h4 className='seller_title'>{ t("dashboard_edit_form.bank") }</h4>
												{/* <input className='input_seller_text' value={bank} onChange={(e) => setBank(e.target.value)} placeholder='https://www.paypal.com/kimbi' /> */}
												<input 
													className='input_seller_text' 
													value={bank} 
													onChange={e => searchBankList(e)}
													onBlur={() =>  setTimeout(() => { setBankDropdownState(false) }, 500)}
													onFocus={() => setBankDropdownState(true)}  
													placeholder= {bank}
													autoComplete='off'
												/>
												<BsChevronDown/>
												<div className={`countrySelectDropDown ${bankDropdownState ? 'showCountryDropDown' : ''}`}>
													{
														bankList && bankList.map(element => (
															<div key={element?.pk} onClick={() => selectBank(element)} className='nationality_dropdownlist'>
																<span className='countryName'>{ element?.name }</span>
															</div>
														))
													}
												</div>
											</div>
										</div>
										<div className='seller_profile_wrapper'>
											<div className='profile'>
												<h4 className='seller_title'>{ t("dashboard_edit_form.swift") }</h4>
												<input className='input_seller_text' value={swift} onChange={(e) => setSwift(e.target.value)} placeholder='567654' />
											</div>
										</div>
										<div className='button_payment_request'>
											<button onClick={() => saveEdit()} className='profile_control_btn'>
												<span className='text'>{ t("dashboard_edit_form.save") }</span>
												{
													isloading && <span className='loading'><ThreeDots /></span>
												}

											</button>
										</div>
									</>
								)
						}
					</>

				) : (
					<>
						{!validated && <div className='button_payment_request'><button onClick={() => requestEdit()} className='profile_control_btn'>{ t("dashboard_edit_form.request") }</button></div>}
						<div className='form_authenticate_user_seller'>
							{
								validated && <VerifySeller handleRequest={request} handleRemoveModal={removeModal} />
							}

						</div>
					</>
				)
			}

		</>
	)
}

const Achievements = () => {
	return (
		<>
			<p></p>
		</>

	)
}

const MySellingBooks = () => {
        const [filterBy, setFilterBy] = useState('')
        const [activeFilter, setActiveFilter] = useState('all')
        const [searchString, setSetsearchString] = useState('')
        const [isloading, setIsloading] = useState(false)
        const [books, setBooks] = useState([])

        const navigate = useNavigate()
        useEffect(() => {
                setIsloading(true)
                fetchMyBooks()
        }, [])
        
        const fetchMyBooks = async() => {
                const res = await getRequest('/seller/ebook/owner-ebook/')
                setBooks(res.data?.results)
                setIsloading(false)
        }
        const filterButton = (state) => {
                setActiveFilter(state)
                setFilterBy(state)
        }

        const filterInput = (state) => {
                setSetsearchString(state)
                filter()
        }

        const filter = (string) => {

        }
       return (
        <div className='seller_mybooks_page'>
                <div className='mybooks_page'>
                        <main className='main_user_section'>
                                <div className='my_seller_book_filterWrapper'>
                                        <div>
                                                <button onClick={() => filterButton('')} className={`filter_by_btn ${activeFilter === '' ? 'filteringByActive': ''}`}>All</button>
                                                <button onClick={() => filterButton('active')} className={`filter_by_btn ${activeFilter === 'active' ? 'filteringByActive': ''}`}>Active</button>
                                                <button onClick={() => filterButton('pending')} className={`filter_by_btn ${activeFilter === 'pending' ? 'filteringByActive': ''}`}>Pending</button>
                                                <button onClick={() => filterButton('regected')} className={`filter_by_btn ${activeFilter === 'regected' ? 'filteringByActive': ''}`}>Regected</button>
                                        </div>
                                        <div className='search_sellerbook_filter'>
                                                <input value={searchString} onChange={e => filterInput(e.target.value)} type='text' placeholder='search...' />
                                        </div>
                                </div>
                                <div className='user_main_grid'>
                                {
                                        isloading ?
                                        <LoadingPage />
                                        :
                                        books && (
											books.length < 1
                                        ?
                                        (<div className='seller_with_no_book'>
                                                <div className='title'><NoBooks /></div>
                                                <button onClick={() => navigate('/book/create-book')}>Create a Book</button>
                                        </div>)
                                        :
                                        books.map(book => (
                                                <SellerBook key={book?.slug} book={book} />
                                        ))
										)
                                }
                                </div>
                        </main>
                </div>
        </div>
       ) 
}