import React from 'react'
import { Carousel } from 'react-responsive-carousel';
import { RiDoubleQuotesL, RiDoubleQuotesR } from 'react-icons/ri'

import './Carousel.css'
import { useTranslation } from 'react-i18next';

const breakPoints = [
  { width: 1, itemsToShow: 2 },
  { width: 200, itemsToShow: 2.5 },
  { width: 240, itemsToShow: 2.9 },
  { width: 270, itemsToShow: 3.3 },
  { width: 320, itemsToShow: 3.7, itemsToScroll: 2 },
  { width: 380, itemsToShow: 4.0 },
  { width: 430, itemsToShow: 4.3 },
  { width: 450, itemsToShow: 4.7 },
  { width: 550, itemsToShow: 5.4 },
  { width: 600, itemsToShow: 6 }
];
const PopularReviews = () => {
  const { t } = useTranslation()
    const reviews = [
        {
            name: "laura jansen",
            color: '#B1E9CD',
            quote: t("testimonial.laura"),
            img: `https://randomuser.me/api/portraits/women/1.jpg`
        },
        {
            name: "Alex Turner",
            color: '#BFE9EB',
            quote: t("testimonial.alex"),
            img: `https://api.multiavatar.com/${encodeURIComponent("Alex Turner")}.svg`
        },
        {
            name: "Maxime Dupont",
            color: '#F9E6BC',
            quote: t("testimonial.maxim"),
            img: `https://api.multiavatar.com/${encodeURIComponent("Maxime Dupont")}.svg`
        },
        {
            name: "Emily Chen",
            color: '#B1E9CD',
            quote: t("testimonial.emily"),
            img: `https://randomuser.me/api/portraits/women/4.jpg`
        },
        {
            name: "David Garcia",
            color: '#FBD0B5',
            quote: t("testimonial.gracia"),
            img: `https://randomuser.me/api/portraits/men/5.jpg`
        }
    ];



    return (
      <section className='carouselContainer'>
        <h2 className='landing_title_center'>{ t('landing.titles.pop_rem') }</h2>
        <Carousel
            autoPlay infiniteLoop interval={3500} 
            breakPoints={breakPoints}
            className="carouselWrapper"
            renderArrowPrev={(onClickHandler, hasPrev, label) =><></>
            }
            renderArrowNext={(onClickHandler, hasNext, label) =><> </>
            }
        >
            {
              reviews.map(review => (
                <div key={review.name} className='popular_review'>
                  <div className='popular_review_person_profile'>
                    <div className='imgWrapper' style={{backgroudColor: review.color}}s>
                       <img src={ review.img } alt={`${review.name}'s avatar`}  style={{ backgroundSize: 'cover', borderRadius: 60}}/>
                    </div>
                  </div>
                  <div className='popular_reviewText'>
                    <div className='quote_body'>
                      <span className='quote_icon' style={{color: review.color}} ><RiDoubleQuotesL /></span>
                      <p>{ review.quote }</p>
                      <h3 className='quote_review_name' >{review.name}</h3>
                      <span className='quote_icon' style={{color: review.color}}><RiDoubleQuotesR /></span>
                    </div>
                  </div>

                </div>
              ))
            }
          </Carousel>
      </section>
    )
}

export default PopularReviews



