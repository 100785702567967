import React from 'react';
import { useTranslation } from 'react-i18next';

export const CustomTooltip = ({ active, payload, label, data = [] }) => {
    const { t } = useTranslation();

    if (active && payload && payload.length > 0) {
        const dataItem = payload[0].payload; // Extract data for the hovered point
        const isMonthlySales = payload[0].dataKey === 'sales'; // Check if it's monthly sales

        const monthData = data.find(item => item.timePeriod === label);

        if (!monthData) return null; // If monthData doesn't exist, return null

        // Tooltip for Monthly Sales
        if (isMonthlySales) {
            return (
                <div className="custom-tooltip">
                    <h4>{label}</h4>
                    <p>{t('statistics.total_monthly_sales')}: XAF {dataItem.sales}</p>
                    <h5>{t('statistics.weekly_breakdown')}:</h5>
                    <ul>
                        {monthData.weeklySales && monthData.weeklySales.length > 0 ? (
                            monthData.weeklySales
                                .filter(week => week.totalEarned > 0)
                                .map((week, index) => (
                                    <li key={index}>
                                        <strong>{week.week}: </strong>XAF {week.totalEarned}
                                    </li>
                                ))
                        ) : (
                            <p>{t('statistics.no_data_available')}</p>
                        )}

                    </ul>
                </div>
            );
        }
    }
    return null;
};
